import { useEffect, useState } from 'react';
import { getImagesForLocationId, likeImage, unlikeImage } from '../../backendclient';
import Typography from '@mui/material/Typography';
import './LocationImages.css';
import { IconButton, Tooltip } from '@mui/material';
import ImageManagementModal from '../ImageManagementModal';
import DisplayWithPrivilege from '../DisplayWithPrivilege';
import { AddAPhoto } from '@mui/icons-material';
import LikeButton from '../LikeButton';
import UsernameLink from '../UsernameLink';

function LocationImages({locationId, className, readOnly}) {
    const [images, setImages] = useState(null);
    const [error, setError] = useState(null);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [reloadImagesToken, setReloadImagesToken] = useState(0);

    useEffect(() => {
        async function handleReload() {
            try {
                const images = await getImagesForLocationId(locationId);
                setImages(images);
            } catch(err) {
                setError(err);
            }
        }
        handleReload();
    }, [locationId, reloadImagesToken]);

    // make sure we can't have an index larger than the number of images we have
    useEffect(() => {
        if (images && activeImageIndex >= images.length) {
            setActiveImageIndex(0);
        }
    }, [activeImageIndex, images]);

    async function handleArrowClick(delta) {
        if (images.length === 1) {
            return;
        }

        const currentI = activeImageIndex;
        let nextI = currentI + delta;
        if (nextI < 0) nextI += images.length;
        nextI %= images.length;
        setActiveImageIndex(nextI);
    }

    if (error) {
        return <Typography color="error">{error.message}</Typography>
    }

    if (!images) {
        return <div className='LocationImages'><Typography>Loading...</Typography></div>
    }

    const activeImage = images[activeImageIndex];

    const renderImageDisplay = () => <div className='ImageContainer'>
        <div className='Arrow Prev' onClick={() => handleArrowClick(-1)}>
            &#10094;
        </div>
        <div className='Arrow Next' onClick={() => handleArrowClick(1)}>
            &#10095;
        </div>
        
        <div className='ImageAndCaption'>
            <div className='ImageAndLoading'>
                {images.map(i => <img key={i.id} hidden={activeImage.id !== i.id} src={`/api/v1/locations/${locationId}/images/${i.id}?size=medium`} alt="User Submitted" />)}
            </div>
            <div className='Caption'>
                <Typography variant='caption'>{activeImageIndex + 1} / {images.length}</Typography>
                {activeImage.caption ? <Typography variant='caption'> - {activeImage.caption}</Typography> : null}
                <Typography variant='caption'> - {activeImage && activeImage.creator ? <UsernameLink user={activeImage.creator} /> : "Unknown"}</Typography>
            </div>
            <div>
                <Typography variant='caption'><a href={`/api/v1/locations/${locationId}/images/${activeImage.id}?size=original`}>View full size</a></Typography>
            </div>
        </div>
    </div>;

    return (
        <div className={className ? 'LocationImages ' + className : 'LocationImages'}> 
            
            {activeImage ? renderImageDisplay() : <div className='NoImagesLabel'>
                <Typography variant='caption'>No Images</Typography>
            </div>}


            <div className="ImageButtons">
                {activeImage ? <LikeButton 
                    likedByUsers={activeImage ? activeImage.likedByUsers : []} 
                    likeFunc={async () => await likeImage(locationId, activeImage.id)}
                    unlikeFunc={async () => await unlikeImage(locationId, activeImage.id)}
                    onChange={() => setReloadImagesToken(x => x+1)}
                    tooltipResourceName="Image"
                /> : null}
                <DisplayWithPrivilege privilege="canEditLocations" >
                    {!readOnly && <Tooltip title="Add/Edit Images"><IconButton variant="contained" onClick={() => setAddModalOpen(true)}><AddAPhoto /></IconButton></Tooltip>}
                </DisplayWithPrivilege>
            </div>

            <ImageManagementModal open={addModalOpen} onClose={() => {setAddModalOpen(false); setReloadImagesToken(t => t+1)}} locationId={locationId} />
        </div>
    );
}

export default LocationImages;
