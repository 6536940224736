import { Marker } from 'react-leaflet'
import { groupIconsByCount, groupOverflowIcon } from '../../markerIcons';

function GroupMarker({group, onClick}) {
    const avgLat = group.reduce((last, location) => last + location.lat, 0) / group.length;
    const avgLng = group.reduce((last, location) => last + location.lng, 0) / group.length;

    return <Marker
        position={[avgLat, avgLng]}
        key={group[0].id}
        icon={groupIconsByCount[group.length] || groupOverflowIcon} 
        eventHandlers={{
            click: onClick
        }}/>
}

export default GroupMarker;