import { Button } from "@mui/material";
import { useState } from "react";
import EditCollectionModal from "../EditCollectionModal";

function AddCollectionButton({style, onSave}) {
    const [modalOpen, setModalOpen] = useState(false);

    return <div style={{display: "flex", ...style}}>
        <Button variant="outlined" onClick={() => setModalOpen(true)}>New Collection</Button>
        <div className="CollectionList">
            <EditCollectionModal open={modalOpen} onCancel={() => setModalOpen(false)} onSave={() => {setModalOpen(false); onSave()}} />
        </div>
    </div>
}

export default AddCollectionButton;
