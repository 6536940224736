import './AdminPage.css';
import ApplicationBar from '../ApplicationBar';
import DisplayWithPrivilege from '../DisplayWithPrivilege';
import UserList from './UserList';
import MergeLocation from './MergeLocations';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import CreateAnnouncement from './CreateAnnouncement';

function AdminPage() {
    const navigate = useNavigate();

    return (
        <div>
            <ApplicationBar />
            
            <div className='Container'>
                <DisplayWithPrivilege privilege={"canViewUsers"}>
                    <UserList />
                </DisplayWithPrivilege>

                <DisplayWithPrivilege privilege={"canInviteUsers"}>
                    <Button onClick={() => navigate("/admin/invite")} variant="contained">Invite new user</Button>
                </DisplayWithPrivilege>

                <DisplayWithPrivilege privilege={"canCreateAnnouncements"}>
                    <CreateAnnouncement />
                </DisplayWithPrivilege>

                <DisplayWithPrivilege privilege={"isSuperUser"}>
                    <MergeLocation />
                </DisplayWithPrivilege>
            </div>
        </div>
    );
}

export default AdminPage;
