import { Checkbox, MenuItem } from "@mui/material";

// TODO rework how we store state to get more things in the query params in a better way
function CollectionList({collections, selectedCollectionId, setSelectedCollection, onlyLiked, setOnlyLiked}) {
    function setActiveCollection(collectionId) {
        if (collectionId === "liked") {
            setOnlyLiked(true);
            setSelectedCollection(null);
        } else if (collectionId) {
            setOnlyLiked(false);
            setSelectedCollection(collectionId);
        } else {
            setOnlyLiked(false);
            setSelectedCollection(null);
        }
    }

    const staticCollections = [
        <MenuItem style={{minHeight: "54px"}} onClick={() => setActiveCollection(null)} key={"all"}>
            <Checkbox checked={!selectedCollectionId && !onlyLiked} />All Locations
        </MenuItem>,
        <MenuItem style={{minHeight: "54px"}} onClick={() => setActiveCollection("liked")} key={"liked"}>
            <Checkbox checked={onlyLiked} />Liked Locations
        </MenuItem>
    ];

    return staticCollections.concat(collections.map(c => <MenuItem style={{minHeight: "54px"}} key={c.id} onClick={() => setActiveCollection(c.id)}>
        <Checkbox checked={selectedCollectionId === c.id} /> {c.name} ({c.locations.length})
    </MenuItem>));
}

export default CollectionList;
