import React from 'react';
import { Typography } from "@mui/material";
import { EVENT_TYPES } from '../../sharedConstants';

function LimitedStringDisplay({limit, children}) {
    if (children.length > limit) {
        return children.substr(0, limit-3) + "...";
    } else {
        return children;
    }
}

function EventMessage({event, LinkComponent}) {
    const defaultMessageStrategy = event => `Unknown event of type ${event.type} for user ${event.OriginatingUser.username}`;

    function LocationLink({location, children}) {
        if (location.deletedAt) {
            return children;
        } else {
            return <LinkComponent to={`/location/${location.id}`}>{children}</LinkComponent>;
        }
    }
    
    function CollectionLink({collection, children}) {
        if (collection.deletedAt) {
            return children;
        } else {
            return <LinkComponent to={`/map?collectionId=${collection.id}`}>{children}</LinkComponent>;
        }
    }

    function UserLink({user, children}) {
        return <LinkComponent to={`/user/${user.id}`}>{children}</LinkComponent>;
    }

    const eventMessageStrategies = {
        [EVENT_TYPES.locationAdded]: event => <LocationLink location={event.Location}>{event.OriginatingUser.username} added a new location '{event.Location.name}'</LocationLink>,
        [EVENT_TYPES.locationModified]: event => <LocationLink location={event.Location}>{event.OriginatingUser.username} updated location '{event.Location.name}'</LocationLink>,
        [EVENT_TYPES.locationImageAdded]: event => <LocationLink location={event.Location}>{event.OriginatingUser.username} added photos to location '{event.Location.name}'</LocationLink>,
        [EVENT_TYPES.locationDeleted]: event => <span>{event.OriginatingUser.username} deleted a location '{event.Location.name}'</span>,
        [EVENT_TYPES.userInvited]: event => event.InvitationToken.createdFromMultiUseCode ? <UserLink user={event.InvitationToken.CreatedUser}>'{event.InvitationToken.CreatedUser.username}' joined the site from a code</UserLink> : <UserLink user={event.InvitationToken.CreatedUser}>{event.OriginatingUser.username} invited a new user '{event.InvitationToken.CreatedUser.username}'</UserLink>,
        [EVENT_TYPES.commentAdded]: event => {
            if (event.Comment && event.Comment.locationId) {
                return <LocationLink location={event.Comment.Location}>{event.OriginatingUser.username} commented '<LimitedStringDisplay limit={70}>{event.Comment.text}</LimitedStringDisplay>'' on location '{event.Comment.Location.name}'</LocationLink>
            } else if (event.Comment && event.Comment.Image) {
                return <LocationLink location={event.Comment.Image.Location}>{event.OriginatingUser.username} commented on a photo</LocationLink>
            } else {
                return null;
            }
        },
        [EVENT_TYPES.announcement]: event => <span>{event.text}</span>,
        [EVENT_TYPES.locationShared]: event => <LocationLink location={event.Location}>{event.OriginatingUser.username} shared the location '{event.Location.name}' with you</LocationLink>,
        [EVENT_TYPES.collectionShared]: event => <CollectionLink collection={event.Collection}>{event.OriginatingUser.username} shared the collection '{event.Collection.name}' with you</CollectionLink>,
        [EVENT_TYPES.locationLiked]: event => <LocationLink location={event.Location}>{event.OriginatingUser.username} liked your location '{event.Location.name}'</LocationLink>,
        [EVENT_TYPES.commentLiked]: event => <LocationLink location={event.Location}>{event.OriginatingUser.username} liked your comment on '{event.Location.name}'</LocationLink>,
        [EVENT_TYPES.imageLiked]: event => <LocationLink location={event.Location}>{event.OriginatingUser.username} liked your image on '{event.Location.name}'</LocationLink>,
    }

    try {
        return <Typography>{(eventMessageStrategies[event.type] || defaultMessageStrategy)(event)}</Typography>
    } catch(err) {
        console.log({
            message: "failed to render event template",
            event: event,
            error: err,
        });
        throw err;
    }
}

// TODO add test for this
export default EventMessage;
