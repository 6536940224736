import React, { useEffect, useState } from 'react';
import { getCollectionById, getLocationsForType } from '../backendclient';
import CurrentUserContext from './CurrentUserContext';

const LocationsContext = React.createContext(null);

function LocationsContextProvider({currentUser, children}) {
    const [locations, setLocations] = useState(null);
    const [reloadToken, setReloadToken] = useState(0);
    const [locationsQuery, setLocationsQuery] = useState({
        locationTypes: [],
        collectionId: null,
        onlyLiked: false,
    });
    const [error, setError] = useState(null);

    // this effect gets locations
    useEffect(() => {
        if (locationsQuery.collectionId) {
            getCollectionById(locationsQuery.collectionId)
                .then(collection => setLocations(collection.locations))
                .catch(setError);
            return;
        }

        if (!locationsQuery.locationTypes || locationsQuery.locationTypes.length === 0) {
            return;
        }

        async function getLocationsWrapper() {
            try {
                const queryParams = {
                    liked: locationsQuery.onlyLiked ? locationsQuery.onlyLiked : null,
                }
                const getLocationPromises = locationsQuery.locationTypes.map(type => getLocationsForType(type, queryParams));
                const results = await Promise.all(getLocationPromises);
                setLocations(results.reduce((prev, current) => [...prev, ...current]));
            } catch(e) {
                console.log(e);
                setError(e);
            }
        }

        getLocationsWrapper();
    }, [locationsQuery, reloadToken, currentUser]);

    function reload() {
        setReloadToken(v => v+1);
    }

    return <LocationsContext.Provider value={{
        locations: locations,
        error: error,
        locationsQuery: locationsQuery,
        setLocationsQuery: setLocationsQuery,
        reloadLocations: reload,
        cacheKey: Math.random() // a random value to assign to this specific update for caching purposes
    }}>
        {children}
    </LocationsContext.Provider>
}

function LocationsContextProviderWrapper(props) {
    return <CurrentUserContext.Consumer>
        {currentUserValue => <LocationsContextProvider currentUser={currentUserValue} {...props} />}
    </CurrentUserContext.Consumer>
}

const exports = {Provider: LocationsContextProviderWrapper, Consumer: LocationsContext.Consumer};;
export default exports;
