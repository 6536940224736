import './UserProfilePage.css';
import ApplicationBar from '../ApplicationBar';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { getUser } from '../../backendclient';
import LocationTypesContext from '../../contexts/LocationTypesContext';
import MessagesContext from '../../contexts/MessagesContext';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import { IconButton, Tooltip, Typography } from '@mui/material';
import IsoDate from '../IsoDate';
import { HelpOutline, Message } from '@mui/icons-material';
import { WEB_SOCKET_EVENT_TYPES } from '../../sharedConstants.js';
import UsernameLink from '../UsernameLink';
const uuid = require('uuid');


function UserProfilePageContent({user, locationTypes, getMessageGroupIdForUser, canMessageUser, currentUser}) {
    const navigate = useNavigate();
    const hiddenLocationTypes = user?.hiddenLocationTypes || {};
    
    function renderLocationTypeListItem(locationType) {
        const isHiddenToUser = hiddenLocationTypes[locationType];
        const style = {
            display: "flex",
        };
        if (isHiddenToUser) {
            style.textDecoration ="line-through";
            style.color = "gray";
        }
        return <div style={style}>
            <Typography className='SectionText' variant="body2">{locationType}</Typography>
            {isHiddenToUser ? <Tooltip title="This location type is not visible to this user"><HelpOutline style={{margin: "auto", marginLeft: "5px"}} fontSize='tiny'/></Tooltip> : null}
        </div>
    }

    async function handleMessageUserClick() {
        const messageGroupId = await getMessageGroupIdForUser();
        navigate(`/messages/${messageGroupId}`);
    }
    
    if (!user || !locationTypes) {
        return "Loading...";
    }

    return <div className='UserProfilePage'>
        <div className='Header'>
            <Typography className='Title' variant="h4">{user.username}</Typography>
            <div className='Buttons'>
                {currentUser.id !== user.id ? <Tooltip title="Send message"><IconButton disabled={!canMessageUser} onClick={handleMessageUserClick}><Message /></IconButton></Tooltip> : null}
            </div>
        </div>
    
        <div className='PageSection'>
            <Typography variant="body2">Account Created: <IsoDate isoString={user?.createdAt} options={{year: "numeric", month: "numeric", day: "numeric"}} /></Typography>
            {user?.invitedFromToken ? <Typography variant="body2">Invited By: <UsernameLink user={user.invitedFromToken?.User} /></Typography> : null}
            <Typography variant="body2">Locations Added: {user.locations?.length || 0}</Typography>
            <Typography variant="body2">Images Added: {user.images?.length || 0}</Typography>
            <Typography variant="body2">Comments Added: {user.comments?.length || 0}</Typography>
            {/* TODO should display canViewSensitive here for other users with that permission */}
        </div>

        <div className='PageSection'>
            <Typography variant='h6'>User Location Types</Typography>
            {Object.keys(locationTypes).sort((a, b) => hiddenLocationTypes[a] ? 1 : -1).map(renderLocationTypeListItem)}
        </div>    
    </div>
}

function UserProfilePage() {
    const params = useParams();
    const userId = params.userId;
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser(userId)
            .then(setUser)
            .catch(console.log);
    }, [userId]);

    // this function handles looking up the message group for the target user or creating one if it doesn't exist
    // TODO maybe consolidate this with the logic in the new message group modal
    async function getMessageGroupIdForUser(messageGroups, sendMessagesEvent, user, currentUser) {
        const candidateMessageGroups = Object.values(messageGroups).filter(messageGroup => messageGroup.users?.length === 2 && messageGroup?.users.filter(u => u.id === user.id).length === 1);
        if (candidateMessageGroups.length > 0) {
            return candidateMessageGroups[0].id;
        }

        if (!currentUser || !user) {
            return null;
        }

        // create a message group for this user if it doesn't exist yet
        const newMessageGroupId = uuid.v4();
        await sendMessagesEvent({
            eventType: WEB_SOCKET_EVENT_TYPES.newMessageGroup,
            data: {
                id: newMessageGroupId,
                users: [currentUser, user]
            }
        });

        return newMessageGroupId;
    }

    return (
        <div>
            <ApplicationBar />
            
            <div className='Container'>
                <CurrentUserContext.Consumer>
                    {currentUser => <LocationTypesContext.Consumer>
                        {({locationTypes}) => <MessagesContext.Consumer>
                            {messagesContextValue => <UserProfilePageContent 
                                user={user} 
                                locationTypes={locationTypes}
                                canMessageUser={messagesContextValue.initialLoadComplete}
                                getMessageGroupIdForUser={async () => await getMessageGroupIdForUser(messagesContextValue.messageGroups, messagesContextValue.sendMessage, user, currentUser)}
                                currentUser={currentUser} />}
                        </MessagesContext.Consumer>}
                    </LocationTypesContext.Consumer>}
                </CurrentUserContext.Consumer>
            </div>
        </div>
    );
}

export default UserProfilePage;
