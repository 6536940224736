import { useEffect, useState } from "react";
import ReactLinkify from "react-linkify";
import IsoDate from "../IsoDate/IsoDate.js";
import UsernameLink from "../UsernameLink/UsernameLink.js";

function Message({currentUser, message, markMessageRead}) {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        function markRead() {
            if (message.isUnread) {
                markMessageRead(message);
            }
        }
        const timeout = setTimeout(markRead, 2200);

        return () => {
            clearTimeout(timeout);
        };
    }, [message, markMessageRead]);

    function renderMessageTime() {
        if (isExpanded) {
            return <div className="MessageTime">
                <IsoDate isoString={message.createdAt} />
            </div>
        } else {
            return null;
        }
    }

    function toggleIsExpanded() {
        setIsExpanded(v => !v);
    }

    let messageClassName = "Message";
    let messageRowClassName = "MessageRow";
    if (currentUser && currentUser.id === message.authorId) {
        messageClassName += " SentMessage";
        messageRowClassName += " SentMessageRow";
    }

    return <div className={messageRowClassName}>
        {message.isUnread ? <div className="MessageUnreadDot" /> : null}
        <div className={messageClassName} onClick={toggleIsExpanded}>
            <div className="MessageAuthor">
                {isExpanded ? <UsernameLink user={message?.author} /> : message?.author?.username}
            </div>
            <div className="MessageBody">
                <ReactLinkify>{message.body.text}</ReactLinkify>
            </div>
            {renderMessageTime()}
        </div>
    </div>
}

export default Message;
