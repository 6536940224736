import { Home, Info, Message, Person, RssFeed, Settings } from "@mui/icons-material";
import { AppBar, Toolbar, Menu, MenuItem, IconButton, Badge, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DisplayWithPrivilege from "../DisplayWithPrivilege";
import './ApplicationBar.css';
import { getPendingEvents } from "../../backendclient";
import MessagesContext from '../../contexts/MessagesContext';
import CurrentUserContext from "../../contexts/CurrentUserContext";


function ApplicationBar({children, currentUser, messageGroups}) {
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const [userMenuAnchorElement, setUserMenuAnchorElement] = useState(null);
    const [pendingEvents, setPendingEvents] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState([]);

    useEffect(() => {
        getPendingEvents()
            .then(setPendingEvents)
            .catch(console.log);
    }, []); // check for pending events on page load

    useEffect(() => {
        if (!messageGroups) {
            return false;
        }

        const newUnreadMessages = [];
    
        Object.values(messageGroups).forEach(messageGroup => {
            messageGroup.messages.forEach(message => {
                if (message.readByUsers.filter(u => u.id === currentUser.id).length === 0 && message.authorId !== currentUser.id) {
                    newUnreadMessages.push(message);
                }
            });
        });

        setUnreadMessages(newUnreadMessages);
    }, [messageGroups, currentUser]);

    return <AppBar className="AppBar" position="static">
        <Toolbar>
            {children || <Link to="/map" style={{textDecoration: "none", color: "unset"}}><Home fontSize='large' /></Link>}

            <div className="SettingsIcons">
                <Badge badgeContent={pendingEvents.length + unreadMessages.length} variant="dot" color="warning">
                    <IconButton onClick={(e) => {setUserMenuOpen(!userMenuOpen); ; setUserMenuAnchorElement(e.target)}} sx={{ color: 'white'}} ><MenuIcon fontSize="large" /></IconButton>
                </Badge>
                <Menu open={userMenuOpen} onClose={() => {setUserMenuOpen(false); setUserMenuAnchorElement(null)}} anchorEl={userMenuAnchorElement}>
                    <Link className="AppBarMenuItem" to="/usersettings">
                        <MenuItem>
                            <Person className="AppBarMenuItemIcon" />
                            <Typography>User Settings</Typography>
                        </MenuItem>
                    </Link>
                    <Link to={"/events"} className="AppBarMenuItem">
                        <MenuItem>
                            <Badge badgeContent={pendingEvents.length <= 10 ? pendingEvents.length : "10+"} color="warning"><RssFeed className="AppBarMenuItemIcon" /></Badge>
                            <Typography>Event Feed</Typography>
                        </MenuItem>
                    </Link>
                    <Link to={"/messages"} className="AppBarMenuItem">
                        <MenuItem>
                            <Badge badgeContent={unreadMessages.length <= 10 ? unreadMessages.length : "10+"} color="warning"><Message className="AppBarMenuItemIcon" /></Badge>
                            <Typography>Messages</Typography>
                        </MenuItem>
                    </Link>
                    
                    <DisplayWithPrivilege privileges={["canViewUsers", "canInviteUsers"]}>
                        <Link to="/admin" className="AppBarMenuItem">
                            <MenuItem>
                                <Settings className="AppBarMenuItemIcon" />
                                <Typography>Admin</Typography>
                            </MenuItem>
                        </Link>
                    </DisplayWithPrivilege>

                    <Link to="/about" className="AppBarMenuItem">
                        <MenuItem>
                            <Info className="AppBarMenuItemIcon" />
                            <Typography>About</Typography>
                        </MenuItem>
                    </Link>
                </Menu>
            </div>
        </Toolbar>
    </AppBar>
}

function ApplicationBarWrapper(props) {
    return <CurrentUserContext.Consumer>
        {currentUserValue => <MessagesContext.Consumer>
            {messagesContextValue => <ApplicationBar {...props} currentUser={currentUserValue} messageGroups={messagesContextValue.messageGroups} />}
        </MessagesContext.Consumer>}
    </CurrentUserContext.Consumer>
}

export default ApplicationBarWrapper;
