import { Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { createComment, getComments } from "../../backendclient";
import Comment from "./Comment";
import './CommentDisplay.css';

function CommentDisplay({locationId, imageId}) {
    const [newCommentText, setNewCommentText] = useState("");
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reloadToken, setReloadToken] = useState(0);

    useEffect(() => {
        getComments({locationId: locationId})
            .then(setComments)
            .catch(console.log);
    }, [locationId, reloadToken]);

    async function onCommentSubmit() {
        if (!newCommentText) {
            return;
        }

        try {
            await setLoading(true);
            await createComment({
                text: newCommentText,
                locationId: locationId,
                imageId: imageId
            });
            setLoading(false);
            setNewCommentText("");
            setReloadToken(r => r+1);
        } catch(e) {
            console.log(e);
            setLoading(false);
        }
    }

    return <div className='CommentDisplay'>
        <div className="CommentSection">
            <Typography variant="h6" className="CommentsTitle">{comments.length} Comments</Typography>
            {comments.map(comment => <Comment key={comment.id} comment={comment} onChange={() => setReloadToken(r => r+1)} />)}
        </div>

        <div className="NewCommentInputSection">
            <Typography>Post new comment</Typography>
            <TextField multiline rows={6} size="small" fullWidth onChange={e => setNewCommentText(e.target.value)} className='Input' value={newCommentText} />
            <Button disabled={loading} variant="contained" onClick={onCommentSubmit}>Submit</Button>
        </div>
    </div>
}

export default CommentDisplay;
