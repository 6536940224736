import { useEffect, useState } from 'react';
import { Button, Checkbox, TextField, Typography } from '@mui/material';
import './UserSettingsPage.css';
import ApplicationBar from '../ApplicationBar';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import { logout, updateUser } from '../../backendclient';
import { useNavigate } from 'react-router';
import InfoFooter from '../InfoFooter';

function UserSettingsPageContent({currentUser}) {
    const [newPasswordValue, setNewPasswordValue] = useState("");
    const [newPassword2Value, setNewPassword2Value] = useState("");
    const [user, setUser] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setUser(currentUser);
        setNewPasswordValue("");
        setCanSave(false);
    }, [currentUser]);

    function handleFieldChange(e) {
        setCanSave(true);
        setUser(u => (
            {
                ...u,
                [e.target.id]: e.target.value
            }
        ));
    }

    async function onSave() {
        await updateUser(user.id, user);
        setNewPasswordValue("");
        setCanSave(false);
        setMessage("Successfully saved settings");
    }
    
    async function onPasswordChange() {
        await updateUser(user.id, {
            "password": newPasswordValue
        });
        setNewPasswordValue("");
        setCanSave(false);
        setMessage("Successfully changed password");
    }

    async function onLogout() {
        await logout()
        navigate("/login");
    }

    function canResetPassword() {
        if (!newPasswordValue) {
            return false;
        }
        if (newPasswordValue.length < 8) {
            return false;
        }
        if (newPasswordValue !== newPassword2Value) {
            return false;
        }
        return true;
    }

    async function handleBooleanUserPreferencesChange(e) {
        setCanSave(true);
        setUser(u => (
            {
                ...u,
                userPreferences: {
                    ...u.userPreferences,
                    [e.target.id]: e.target.checked
                }
            }
        ));
    }

    if (!user) {
        return "Loading..."
    }

    return (
        <div>
            <ApplicationBar />
            
            <div className='Container'>
                {message ? <Typography>{message}</Typography> : null}

                <h4>User Settings</h4>
                <div className='UserSettingsSection'>
                    <TextField fullWidth label="Username" variant="outlined" value={user.username} id="username" onChange={handleFieldChange} />
                </div>
                <div className='UserSettingsSection'>
                    <TextField fullWidth label="Email" variant="outlined" value={user.email} id="email" onChange={handleFieldChange} />
                </div>
                <h4>Notification Settings</h4>
                <div className='UserSettingsSection'>
                    <div className='SettingsCheckbox'>
                        <Checkbox id="enableEmailNotifications" checked={user.userPreferences.enableEmailNotifications} defaultChecked={true} onChange={handleBooleanUserPreferencesChange} />
                        <Typography>Enable Weekly Emails</Typography>
                    </div>
                    <div className='SettingsCheckbox'>
                        <Checkbox id="enableEmailsForDirectMessages" checked={user.userPreferences.enableEmailsForDirectMessages} defaultChecked={true} onChange={handleBooleanUserPreferencesChange} />
                        <Typography>Enable Emails For New Messages</Typography>
                    </div>
                </div>
                <Button disabled={!canSave} variant="contained" onClick={onSave}>Save</Button>
                
                <h4>Set New Password</h4>
                <div className='UserSettingsSection'>
                    <TextField fullWidth label="New Password" variant="outlined" type="password" value={newPasswordValue} onChange={e => setNewPasswordValue(e.target.value)} />
                </div>
                <div className='UserSettingsSection'>
                    <TextField fullWidth label="Repeat New Password" variant="outlined" type="password" value={newPassword2Value} onChange={e => setNewPassword2Value(e.target.value)} />
                </div>
                <Button disabled={!canResetPassword()} variant="contained" onClick={onPasswordChange}>Reset Password</Button>

                <h4>Logout</h4>
                <div className='UserSettingsSection'>
                    <Button onClick={onLogout} variant="contained">Logout</Button>
                </div>
            </div>

            <InfoFooter />
        </div>
    );
}

function UserSettingsPage() {
    return <CurrentUserContext.Consumer>
        {value => <UserSettingsPageContent currentUser={value} />}
    </CurrentUserContext.Consumer>
}

export default UserSettingsPage;
