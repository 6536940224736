import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { createLocation, getCollections } from '../../backendclient';
import { Marker } from 'react-leaflet'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Menu, MenuItem, IconButton, Checkbox, Input, Divider } from '@mui/material';
import Map from '../Map';
import './MapPage.css';
import { addLocationIcon } from '../../markerIcons';
import ApplicationBar from '../ApplicationBar';
import MapIcon from '@mui/icons-material/Map';
import { ArrowBack, Cancel, Collections, FilterList, Search } from '@mui/icons-material';
import LocationMarker from './LocationMarker';
import GroupMarker from './GroupMarker';
import CollectionList from './CollectionList';
import LocationsContext from '../../contexts/LocationsContext';
import LocationTypesContext from '../../contexts/LocationTypesContext';
import hash from 'object-hash';
import SearchDisplay from '../SearchDisplay';
import MapButtons from './MapButtons';

const DEFAULT_ZOOM_LEVEL = 7;
const DEFAULT_MAP_CENTER = [40.11545, -111.82786]
const MAX_CLOSENESS_THRESHOLD = 15;
const MAP_MENU_CONTEXT_TOP = "top";
const MAP_MENU_CONTEXT_FILTER = "filter";

// if a lat and lng is specified as query params we want to focus the map there
function getStarterMapConfig(searchParams) {
    const config = JSON.parse(localStorage.getItem("MAP_CONFIG")) || {};
    if (searchParams.get("lat") && searchParams.get("lng")) {
        config['mapCenter'] = [searchParams.get("lat"), searchParams.get("lng")];
    }
    if (searchParams.get("zoom")) {
        config['zoom'] = searchParams.get('zoom')
    }
    return config;
}

function MapPage({locations, locationTypes, locationsQuery, setLocationsQuery, locationsContextCacheKey}) {
    const [searchParams, setSearchParams ] = useSearchParams();
    const [newLocationPos, setNewLocationPos] = useState(searchParams.get("lat") && searchParams.get("lng") && searchParams.get("showMarker") === "true" ? [searchParams.get("lat"), searchParams.get("lng")] : null);
    const [mapConfig, setMapConfig] = useState(getStarterMapConfig(searchParams));
    const navigate = useNavigate();
    const dragableMarkerRef = useRef(null);
    const [mapMenuOpen, setMapMenuOpen] = useState(false);
    const [mapMenuContext, setMapMenuContext] = useState(MAP_MENU_CONTEXT_TOP);
    const [selectedLocationTypes, setSelectedLocationTypes] = useState(JSON.parse(localStorage.getItem("SELECTED_LOCATION_TYPES")) || {});
    const [menuAnchorElement, setMenuAnchorElement] = useState(null);
    const [searchInputValue, setSearchInputValue] = useState(searchParams.get("searchString") || ""); 
    const [searchString, setSearchString] = useState(searchParams.get("searchString") || "");
    const [locationIdsExcludedFromGrouping, setLocationIdsExcludedFromGrouping] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [effectiveZoomForGrouping, setEffectiveZoomForGrouping] = useState(mapConfig.zoom || DEFAULT_ZOOM_LEVEL);
    const [collections, setCollections] = useState(null);
    const [center, setCenter] = useState(mapConfig.mapCenter || DEFAULT_MAP_CENTER);
    const [groupingCache, setGroupingCache] = useState({});
    const [locationsOverrideForSearch, setLocationsOverrideForSearch] = useState(null);
    const [mapBounds, setMapBounds] = useState(null);
    const recordMapConfigTimeout = useRef();

    const selectedCollectionId = searchParams.get("collectionId");
    const onlyLiked = searchParams.get("liked");

    // TODO maybe pull this up into locations context or its own context
    // this effect loads collections
    useEffect(() => {
        getCollections()
            .then(setCollections)
            .catch(console.log);
    }, []);

    useEffect(() => {
        setEffectiveZoomForGrouping(Math.round(mapConfig.zoom));
        setLocationIdsExcludedFromGrouping([]);
    }, [mapConfig.zoom]);

    // This effect ensures we have values in selectedLocationTypes for all location types
    useEffect(() => {
        if (!locationTypes) {
            return;
        }

        Object.keys(locationTypes).forEach(locationType => {
            if (!Object.keys(selectedLocationTypes).includes(locationType)) {
                setSelectedLocationTypes(value => ({
                    ...value,
                    [locationType]: true
                }));
            }
        });
    }, [locationTypes, selectedLocationTypes]);

    // this effect sets the parameters for locationsQuery
    useEffect(() => {
        const enabledTypes = [];
        for (const type of Object.keys(selectedLocationTypes) || {}) {
            if (selectedLocationTypes[type]) enabledTypes.push(type);
        }

        const newLocationsQuery = {
            ...locationsQuery,
            collectionId: selectedCollectionId,
            locationTypes: enabledTypes,
            onlyLiked: onlyLiked,
        };

        // only update the locations query if it's going to set a new value
        if (JSON.stringify(newLocationsQuery) !== JSON.stringify(locationsQuery)) {
            setLocationsQuery(newLocationsQuery);
        }
    }, [selectedLocationTypes, selectedCollectionId, setLocationsQuery, locationsQuery, onlyLiked]);

    // this effect writes selected location types to local storage
    useEffect(() => {
        localStorage.setItem("SELECTED_LOCATION_TYPES", JSON.stringify(selectedLocationTypes));
    }, [selectedLocationTypes]);

    useEffect(() => {
        if (!locations) {
            return;
        }

        function getMarkersForLocations(locations) {
            const locationsByGroupKey = {};
            const groupsByLocationId = {};
            const groupPositionsByGroupKey = {};

            let closenessThreshold = 1500 / Math.pow(3, effectiveZoomForGrouping);
            if (effectiveZoomForGrouping === 0) {
                closenessThreshold = MAX_CLOSENESS_THRESHOLD;
            } else if (effectiveZoomForGrouping >= 10) {
                return locations.map(l => <LocationMarker key={l.id} location={l} />)
            } else {
                closenessThreshold = Math.min(MAX_CLOSENESS_THRESHOLD, closenessThreshold);
            }
    
            locations.forEach(location => {
                const groupKey = location.id;
    
                // location is excluded from grouping - put in its own group
                if (locationIdsExcludedFromGrouping.includes(location.id)) {
                    locationsByGroupKey[groupKey] = [location];
                    groupsByLocationId[location.id] = groupKey;
                    return;
                }

                for (const key of Object.keys(groupPositionsByGroupKey)) {
                    const [groupLat, groupLng] = groupPositionsByGroupKey[key];
                    if (Math.abs(groupLat - location.lat) < closenessThreshold && Math.abs(groupLng - location.lng) < closenessThreshold) {
                        locationsByGroupKey[key].push(location);
                        groupsByLocationId[location.id] = key;
                        return;
                    }
                }
    
                // add location to group
                locationsByGroupKey[groupKey] = [location];
                groupsByLocationId[location.id] = groupKey;
                groupPositionsByGroupKey[groupKey] = [location.lat, location.lng];
            });
    
            return Object.entries(locationsByGroupKey).map(([groupKey, group]) => {
                if (group.length === 1) {
                    return <LocationMarker key={groupKey} location={group[0]} />
                } else {
                    return <GroupMarker key={groupKey} group={group} onClick={() => setLocationIdsExcludedFromGrouping(value => [...value, ...group.map(g => g.id)])} />
                }
            });
        }

        const cacheKey = hash({
            locationsContextCacheKey,
            effectiveZoomForGrouping,
            locationIdsExcludedFromGrouping,
        });
        if (locationsOverrideForSearch) {
            // for the case where we're displaying a set of locations returned from search
            setMarkers(getMarkersForLocations(locationsOverrideForSearch));
        } else if (groupingCache[cacheKey]) {
            setMarkers(groupingCache[cacheKey]);
        } else {
            const markers = getMarkersForLocations(locations);
            setGroupingCache(v => ({
                ...v,
                [cacheKey]: markers
            }));
            setMarkers(markers);
        }
    }, [groupingCache, locations, locationIdsExcludedFromGrouping, effectiveZoomForGrouping, locationsContextCacheKey, locationsOverrideForSearch]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSearchString(searchInputValue);
            setSearchParams(v => {v.set("searchString", searchInputValue); return v}, {replace: true});
        }, 1500);

        return () => {
            clearTimeout(timeout);
        }
    }, [searchInputValue, setSearchParams]);

    const onMapMove = useCallback((e) => {
        function recordMapConfig() {
            const mapCenter = [e.target.getCenter().lat, e.target.getCenter().lng];
        
            const newMapConfig = {
                mapCenter: mapCenter,
                zoom: e.target.getZoom(),
                bounds: e.target.getBounds()
            };

            setMapConfig(newMapConfig);
            localStorage.setItem("MAP_CONFIG", JSON.stringify(newMapConfig));
        }

        // debounce setting the map config to prevent writing to local storage too much
        clearTimeout(recordMapConfigTimeout.current)
        const timeout = setTimeout(recordMapConfig, 200);
        recordMapConfigTimeout.current = timeout;
    }, []);

    const handleSearchResultsChange = useCallback((results) => {
        setLocationsOverrideForSearch(results?.locations);

        if (results?.locations && results.locations.length > 0) {
            const maxPoint = [results.locations[0].lat, results.locations[0].lng]
            const minPoint = [results.locations[0].lat, results.locations[0].lng]

            // find the max and min points from the search results
            // to focus the map view on
            for (const location of results.locations) {
                if (location.lat > maxPoint[0]) {
                    maxPoint[0] = location.lat;
                }
                if (location.lng > maxPoint[1]) {
                    maxPoint[1] = location.lng;
                }
                if (location.lat < minPoint[0]) {
                    minPoint[0] = location.lat;
                }
                if (location.lng < minPoint[1]) {
                    minPoint[1] = location.lng;
                }
            }
            setMapBounds([maxPoint, minPoint]);
        } else {
            setMapBounds(null);
        }
    }, []);

    // Toggle the selected location types
    async function onSelectedLocationTypeClick(type) {
        setSelectedLocationTypes(currentValue => ({
            ...currentValue,
            [type]: !currentValue[type]
        }));
    }

    function onSelectAllClick() {
        setSelectedLocationTypes(v => {
            const newValue = {};
            Object.keys(v).forEach(k => newValue[k] = !isAllSelected())
            return newValue;
        });
    }

    function isAllSelected() {
        return selectedLocationTypes && Object.values(selectedLocationTypes).filter(v => v !== true).length === 0;
    }

    function handleMyLocationButtonClick() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setCenter([position.coords.latitude, position.coords.longitude]);
                setNewLocationPos([position.coords.latitude, position.coords.longitude]);
            });
        }
    }

    function handleOnlyLikedChange(onlyLiked) {
        if (onlyLiked) {
            setSearchParams(v => {v.set("liked", "true"); return v});
        } else {
            setSearchParams(v => {v.delete("liked"); return v});
        }
    }

    function getMenuContentForContext(context) {
        if (!selectedLocationTypes || !locationTypes) {
            return;
        }
        return {
            [MAP_MENU_CONTEXT_TOP]: <div>
                {collections ? 
                    <CollectionList 
                        collections={collections} 
                        setSelectedCollection={collectionId => collectionId ? setSearchParams(v => {v.set("collectionId", collectionId); return v}) : setSearchParams(v => {v.delete("collectionId"); return v})}
                        onlyLiked={onlyLiked}
                        setOnlyLiked={handleOnlyLikedChange}
                        selectedCollectionId={selectedCollectionId}
                /> : "Loading..."}

                <Divider />

                <MenuItem style={{minHeight: "54px"}} onClick={() => navigate("/collections")}>
                    <Collections style={{marginLeft: "10px", marginRight: "8px"}} />Collections
                </MenuItem>

                <MenuItem style={{minHeight: "54px"}} onClick={() => setMapMenuContext(MAP_MENU_CONTEXT_FILTER)}>
                    <FilterList style={{marginLeft: "10px", marginRight: "8px"}} />Filters
                </MenuItem>
            </div>,
            [MAP_MENU_CONTEXT_FILTER]: <div>
                <MenuItem style={{minHeight: "54px"}} onClick={onSelectAllClick}>
                    <Checkbox checked={isAllSelected()} />All
                </MenuItem>

                {Object.keys(locationTypes).map(type => <MenuItem key={type} onClick={() => onSelectedLocationTypeClick(type)}>
                    <Checkbox checked={Boolean(selectedLocationTypes[type])} /> {type}
                </MenuItem>)}
                
                <MenuItem style={{minHeight: "54px"}} onClick={() => setMapMenuContext(MAP_MENU_CONTEXT_TOP)}>
                    <ArrowBack style={{marginLeft: "10px", marginRight: "8px"}} />Back
                </MenuItem>
            </div>
        }[context];
    }

    async function onCreateLocation() {
        const location = await createLocation({
            ...Object.values(locationTypes)[0],
            isHidden: true,
            lat: newLocationPos[0],
            lng: newLocationPos[1]
        });
        navigate(`/edit/${location.id}?isCreate=true`)
    }

    const dragableMarkerEventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = dragableMarkerRef.current
                if (marker != null) {
                    setNewLocationPos([marker.getLatLng().lat, marker.getLatLng().lng]);
                }
            }
        }),
    []);

    const newLocationMarker = <Marker 
        draggable 
        icon={addLocationIcon} 
        position={newLocationPos}
        ref={dragableMarkerRef}
        eventHandlers={dragableMarkerEventHandlers}
    />

    return (
        <div>
            <ApplicationBar>
                <IconButton onClick={(e) => {setMapMenuOpen(!mapMenuOpen); setMenuAnchorElement(e.target); setMapMenuContext(MAP_MENU_CONTEXT_TOP)}} sx={{ color: 'white'}} ><MapIcon fontSize="large" /></IconButton>
                <Menu open={mapMenuOpen} onClose={() => {setMapMenuOpen(false); setMenuAnchorElement(null)}} anchorEl={menuAnchorElement}>
                    {getMenuContentForContext(mapMenuContext)}
                </Menu>
                <form onSubmit={(e) => {e.preventDefault(); setSearchString(searchInputValue)}}>
                    <Input className='SearchInput' disableUnderline placeholder="Search" size='small' value={searchInputValue} onChange={(e) => setSearchInputValue(e.target.value)}/>

                    {searchInputValue ? 
                    <IconButton sx={{ color: 'white'}} onClick={() => {
                        setSearchInputValue(""); 
                    }}><Cancel size="Large" /></IconButton> : 
                    <IconButton sx={{ color: 'white'}} type="submit"><Search size="Large" /></IconButton>}
                </form>
            </ApplicationBar>

            <div style={{height: "calc(100% - 64px)", width: '100%', position: "fixed"}}>
                <Map onMove={onMapMove} zoom={mapConfig.zoom || DEFAULT_ZOOM_LEVEL} center={center} fitBounds={mapBounds}>
                    {markers}
                    {newLocationPos && newLocationMarker}
                    <MapButtons
                        newLocationPos={newLocationPos}
                        onAddButton={() => setNewLocationPos(mapConfig.mapCenter)}
                        onCancelButton={() => setNewLocationPos(null)}
                        onConfirmButton={onCreateLocation}
                        onMyLocationButton={handleMyLocationButtonClick}/>
                </Map>
            </div>

            <SearchDisplay onClose={() => setSearchInputValue("")} searchString={searchString} mapConfig={mapConfig} onChange={handleSearchResultsChange} />
        </div>
    );
}

function MapPageWrapper(props) {
    return <LocationTypesContext.Consumer>
        {locationTypesContextValue => <LocationsContext.Consumer>
            {locationsContextValue => <MapPage 
                locations={locationsContextValue.locations}
                locationsQuery={locationsContextValue.locationsQuery}
                setLocationsQuery={locationsContextValue.setLocationsQuery}
                error={locationsContextValue.error}
                locationTypes={locationTypesContextValue.locationTypes}
                locationsContextCacheKey={locationsContextValue.cacheKey}
                {...props}
            />}
        </LocationsContext.Consumer>}
    </LocationTypesContext.Consumer>
}

export default MapPageWrapper;
