import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router';
import './InvitationPage.css';
import { Link, useSearchParams } from 'react-router-dom';
import { createUserWithInvitationToken, login } from '../../backendclient';
import { MINIMUM_PASSWORD_LENGTH, MINIMUM_USERNAME_LENGTH } from '../../sharedConstants';

function InvitationPage({onLogin}) {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();
    const tokenId = searchParams.get("token");
    const [userInfo, setUserInfo] = useState({
        username: "",
        email: "",
        password: ""
    });

    function handleChange(e) {
        setUserInfo(userInfo => ({
            ...userInfo,
            [e.target.id]: e.target.value.trim() // we trim this because sometimes phones add trailing spaces
        }));
    }

    function getUserInfoValidationError() {
        if (!userInfo.email?.includes("@") && !userInfo.email?.includes(".")) {
            return new Error("Please provide a valid email address");
        } else if (userInfo.password.length < MINIMUM_PASSWORD_LENGTH) {
            return new Error(`Your password must be at least ${MINIMUM_PASSWORD_LENGTH} characters long`)
        } else if (userInfo.username.length < MINIMUM_USERNAME_LENGTH) {
            return new Error(`Your username must be at least ${MINIMUM_USERNAME_LENGTH} characters long`)
        } else if (userInfo.password !== userInfo.password2) {
            return new Error("The provided password fields do not match");
        }
        return null;
    }

    async function onSubmit() {
        await setError(null);

        // check user info - dispaly an error if it is invalid
        const userInfoValidationError = getUserInfoValidationError();
        if (userInfoValidationError) {
            setError(userInfoValidationError);
            return;
        }

        // make the request to the backend - display an error if one is returned
        try {
            await createUserWithInvitationToken(tokenId, userInfo)
            await login(userInfo);
            onLogin();
            navigate("/");
        } catch (e) {
            if (e.response && e.response.data.error) {
                const userFriendlyErrorMessages = {
                    "expired invitation token": "This invitation token has already been used. Did you already create an account?",
                    "username already exists": "An account with this username already exists...",
                    "email already exists": "An account with this email already exists. Do you want to try logging in instead?",
                    "username cannot contain spaces": "Your username cannot contain spaces",
                };
                if (userFriendlyErrorMessages[e.response.data.error]) {
                    e.message = userFriendlyErrorMessages[e.response.data.error];
                }
            }
            setError(e);
        }
    }

    function canSubmit() {
        if (!(userInfo.username && userInfo.email && userInfo.password)) {
            return false;
        }
        return true;
    }
    
    return (
        <div className='Container InvitationPage'>
            <form onSubmit={(e) => {
                e.preventDefault();
            }}>
                <h3>Create an Account</h3>
                
                <h5>User Info</h5>
                <div>
                    <TextField label="Email" id="email" onChange={handleChange} />
                </div>
                <div>
                    <TextField label="Username" id="username" onChange={handleChange} />
                </div>

                <h5>Password</h5>
                <div>
                    <TextField label="Password" id="password" type="password" onChange={handleChange} />
                </div>
                <div>
                    <TextField label="Repeat Password" id="password2" type="password" onChange={handleChange} />
                </div>

                <div className='CreateAccountSubmit'>
                    <Button type='submit' disabled={!canSubmit()} onClick={onSubmit} variant='contained'>Create Account</Button>
                </div>
                <div className='ErrorMessage'>
                    {error ? <Typography color="error"><span>{error.message}</span></Typography> : null}
                </div>
                <div className='ContactEmail'>
                    <div><Typography variant="caption">Already have an account? <Link to="/login">Login here.</Link></Typography></div>
                    <div><Typography variant="caption">Trouble creating an account? Contact us at <a href="mailto:admin@goldhillstradingpost.net">admin@goldhillstradingpost.net</a></Typography></div>
                </div>
            </form>
        </div>
    );
}

export default InvitationPage;
