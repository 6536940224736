import { useEffect, useState, useRef, useMemo } from 'react';
import { getLocationById, updateLocation, deleteLocationById, getDefaultLocationValues } from '../../backendclient';
import Typography from '@mui/material/Typography';
import { TextField, Select, MenuItem, Button, Divider, FormControl } from '@mui/material';
import { Marker } from 'react-leaflet'
import { useNavigate, useParams } from 'react-router';
import './EditPage.css';
import Map from '../Map';
import EditPageSection from './EditPageSection';
import { useSearchParams } from 'react-router-dom';
import { defaultIcon, locationTypeIcons } from '../../markerIcons';
import ApplicationBar from '../ApplicationBar';
import LocationImages from '../LocationImages/LocationImages';
import LocationFiles from '../LocationFiles/LocationFiles';
import DisplayWithPrivilege from '../DisplayWithPrivilege';
import InfoFooter from '../InfoFooter';
import LocationsContext from '../../contexts/LocationsContext';

const LOCATION_VISIBILITY_OPEN = "open";
const LOCATION_VISIBILITY_SENSITIVE = "sensitive";
const LOCATION_VISIBILITY_PRIVATE = "private";

function EditPage({onSave}) {
    const [location, setLocation] = useState(null);
    const [error, setError] = useState(null);
    const [newLocationType, setNewLocationType] = useState(null);
    const params = useParams();
    const locationId = params.locationId;
    const navigate = useNavigate();
    const dragableMarkerRef = useRef(null);
    const [searchParams, ] = useSearchParams();
    const isCreate = searchParams.get('isCreate');
    const [defaultLocationValues, setDefaultLocationValues] = useState(null);
    const [loading, setLoading] = useState(false);
 
    useEffect(() => {
        getLocationById(locationId)
            .then(setLocation)
            .catch(setError);
    }, [locationId]);

    useEffect(() => {
        if (newLocationType && defaultLocationValues) {
            const defaultLocationValue = defaultLocationValues[newLocationType];

            setLocation(location => {
                const output = {
                    ...defaultLocationValue,
                    lat: searchParams.get("lat") || 44, 
                    lng: searchParams.get("lng") || -111
                };

                // Handle switching types without overwriting values the user has entered already
                if (location) {
                    for (const key of Object.keys(location)) {
                        if (location[key] && Object.keys(defaultLocationValue).includes(key)) {
                            if (key !== "fields") output[key] = location[key];
                        }
                    }
                    
                    output.fields = {};
                    for (const key of Object.keys(defaultLocationValue.fields)) {
                        output.fields[key] = location.fields[key] || defaultLocationValue.fields[key];
                    }
                }

                return output;
            });
        }
    }, [newLocationType, searchParams, defaultLocationValues])

    useEffect(() => {
        getDefaultLocationValues()
            .then(setDefaultLocationValues)
            .catch(setError);
    }, [setDefaultLocationValues])

    async function onSaveWrapper() {
        try {
            await setLoading(true);

            // if this is a location creation unset the hidden field when we save it
            if (isCreate) {
                location.isHidden = false;
            }
            
            const updatedLocation = await updateLocation(location);
            if (onSave) onSave();
            navigate(`/location/${updatedLocation.id}`);
        } catch(e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }

    // TODO add modal confirming delete
    async function onDelete() {
        try {
            await deleteLocationById(locationId);
            if (onSave) onSave();
            navigate('/map');
        } catch(e) {
            setError(e);
        }
    }

    function handleChange(e) {
        const field = e.target.id || e.target.name;
        const value = e.target.value;
        
        setLocation(location => ({
            ...location,
            [field]: value
        }));
    }

    function handleFieldChange(e) {
        const fieldName = e.target.id || e.target.name;
        const value = e.target.value;

        const newLocation = {
            ...location
        };
        newLocation.fields[fieldName].value = value;

        setLocation(newLocation);
    }

    function handleVisibilityLevelChange(e) {
        if (e.target.value === LOCATION_VISIBILITY_OPEN) {
            setLocation(l => ({
                ...l,
                isSensitive: false,
                isPrivate: false
            }));
        } else if (e.target.value === LOCATION_VISIBILITY_SENSITIVE) {
            setLocation(l => ({
                ...l,
                isSensitive: true,
                isPrivate: false
            }));
        } else if (e.target.value === LOCATION_VISIBILITY_PRIVATE) {
            setLocation(l => ({
                ...l,
                isSensitive: false,
                isPrivate: true
            }));
        }
    }

    function getVisibilityLevelValue() {
        if (location.isSensitive) {
            return LOCATION_VISIBILITY_SENSITIVE;
        } else if (location.isPrivate) {
            return LOCATION_VISIBILITY_PRIVATE;
        } else {
            return LOCATION_VISIBILITY_OPEN;
        }
    }

    function getVisibilityLevelCaption() {
        return {
            [LOCATION_VISIBILITY_SENSITIVE]: "Sensitive locations are only visible to trusted members.",
            [LOCATION_VISIBILITY_PRIVATE]: "Private locations are only available to you and those you explicitly share them with.",
            [LOCATION_VISIBILITY_OPEN]: "Open locations are visible to all members.",
        }[getVisibilityLevelValue()];
    }

    function renderFieldSection(field) {
        const fieldTypeStrategies = {
            "string": (field) => <TextField multiline rows={6} fullWidth onChange={handleFieldChange} className='Input' id={field.name} value={location.fields[field.name].value} />,
            "choice": (field) => <Select value={location.fields[field.name].value} onChange={handleFieldChange} name={field.name}>
                {field.options.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
            </Select>,
            "number": (field) => <TextField type="number" onChange={handleFieldChange} className='Input' id={field.name} value={location.fields[field.name].value} />
        }

        let sectionTitle = field.name;
        if (field.unit) {
            sectionTitle += ` (${field.unit})`;
        }

        return <EditPageSection title={sectionTitle} key={field.name}>
            {fieldTypeStrategies[field.type](field)}
        </EditPageSection>
    }

    function canSave() {
        if (!location.name) return false;
        if (!location.type) return false;
        if (!location.lat || !location.lng) return false;
        if (loading) return false;
        return true;
    }

    const dragableMarkerEventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = dragableMarkerRef.current
                if (marker != null) {
                    setLocation(location => ({
                        ...location,
                        lat: marker.getLatLng().lat,
                        lng: marker.getLatLng().lng
                    }));
                }
            }
        }),
    []);

    if (error) {
        return <Typography color="error">{error.message}</Typography>
    }

    if (!defaultLocationValues || !location) {
        return <Typography>Loading...</Typography>
    }

    const lat = parseFloat(location.lat) || 0;
    const lng = parseFloat(location.lng) || 0;

    return (
        <div>
            <ApplicationBar />

            <div className='Container EditPage'>
                <EditPageSection title="Location Type">
                    <Select name="type" onChange={(e) => {handleChange(e); setNewLocationType(e.target.value)}} value={location.type}>
                        {Object.keys(defaultLocationValues).map(key => <MenuItem key={key} value={key}>{key}</MenuItem>)}
                    </Select>
                </EditPageSection>

                <EditPageSection title="Location Name">
                    <TextField fullWidth autoComplete='off' onChange={handleChange} id="name" value={location.name} />
                </EditPageSection>

                <LocationImages className="EditPageSection" locationId={locationId} />

                <Map style={{height: 400}} center={[lat, lng]} zoom={15}>
                    <Marker 
                        ref={dragableMarkerRef} 
                        position={[lat, lng]} 
                        draggable
                        eventHandlers={dragableMarkerEventHandlers}
                        icon={locationTypeIcons[location.type] || defaultIcon}
                    />
                </Map>
                <div className='EditPageSection'>
                    <TextField className='LocationEditField' size="small" onChange={handleChange} id="lat" label="Lat" value={location.lat} />
                    <TextField className='LocationEditField' size="small" onChange={handleChange} id="lng" label="Lng" value={location.lng} />
                    <DisplayWithPrivilege privilege="canDeleteLocations">
                        {!isCreate && <Button id='delete-button' style={{float: "right", margin: "auto"}} variant="outlined" color="error" onClick={onDelete}>Delete</Button>}
                    </DisplayWithPrivilege>
                </div>

                <EditPageSection className="LocationVisibility" title="Location Visibility">
                    <FormControl>
                        <Select value={getVisibilityLevelValue()} onChange={handleVisibilityLevelChange}>
                            <MenuItem value={LOCATION_VISIBILITY_OPEN}>Open</MenuItem>
                            <MenuItem value={LOCATION_VISIBILITY_SENSITIVE}>Sensitive</MenuItem>
                            <MenuItem value={LOCATION_VISIBILITY_PRIVATE}>Private</MenuItem>
                        </Select>
                    </FormControl>
                    <div className='VisibilityLevelCaption'>
                        <Typography variant="body2">{getVisibilityLevelCaption()}</Typography>
                    </div>
                </EditPageSection>

                {Object.values(location.fields).map(renderFieldSection)}

                <LocationFiles locationId={locationId} canEdit={true} />

                <Divider />

                <div className='EditPageSection EditPageButtons'>
                    <Button variant="outlined" size='large' onClick={() => navigate(-1)}>Cancel</Button>
                    <Button variant="contained" size='large' style={{marginLeft: "auto"}} onClick={onSaveWrapper} disabled={!canSave()}>Save</Button>
                </div>
                <div>
                    {loading ? "Loading..." : null}
                </div>
            </div>

            <InfoFooter />
        </div>
    );
}

function EditPageWrapper(props) {
    return <LocationsContext.Consumer>
        {locationsContextValue => <EditPage {...props} onSave={locationsContextValue.reloadLocations} />}
    </LocationsContext.Consumer>
}

export default EditPageWrapper;
