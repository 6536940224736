import { Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { createEvent } from '../../backendclient';

function CreateAnnouncement() {
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    async function onSubmit() {
        try {
            await setLoading(true);
            await createEvent({
                type: "announcement",
                text: text
            });
            setText("");
            setMessage("Success!")
            setLoading(false);
        } catch(err) {
            console.log(err);
            setLoading(false);
        }
    }

    return (
        <div className='AdminPageSection'>
            <h3>Create Announcement</h3>
            <TextField multiline rows={6} size="small" fullWidth onChange={e => setText(e.target.value)} value={text} />
            <Button style={{marginTop: "15px"}} disabled={loading} variant="contained" onClick={onSubmit}>Submit</Button>
            <div><Typography variant="caption">{message}</Typography></div>
        </div>
    );
}

export default CreateAnnouncement;
