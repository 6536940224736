import axios from 'axios';

const sessionTokenHeader = "x-session-token";

function makeAuthenticatedRequestHeaders(headers) {
    return {
        ...headers,
        [sessionTokenHeader]: localStorage.getItem(sessionTokenHeader)
    }
}

export async function getLocationById(locationId) {
    const res = await axios.get(`/api/v1/locations/${locationId}`, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data.location;
}

export async function getLocationsForType(locationType, options) {
    options = options || {};
    const params = {
        ...options,
        type: locationType,
    };

    const res = await axios.get("/api/v1/locations", {
        params: params,
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data.locations;
}

export async function searchLocations(searchString, searchLocation) {
    const body = {
        searchString: searchString,
        searchLocation: searchLocation
    };
    const res = await axios.post("/api/v1/locations/search", body, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data.locations;
}

export async function createLocation(location) {
    const res = await axios.post("/api/v1/locations", {
        location: location,
    }, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data.location;
}

export async function updateLocation(location) {
    const res = await axios.put("/api/v1/locations", {
        location: location
    }, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data.location;
}

export async function deleteLocationById(locationId) {
    await axios.delete(`/api/v1/locations/${locationId}`, {
        headers: makeAuthenticatedRequestHeaders()
    });
}

export async function login(loginInfo) {
    const res = await axios.post('/api/v1/login', loginInfo);
    localStorage.setItem(sessionTokenHeader, res.data.sessionToken);
}

export async function logout() {
    await axios.post('/api/v1/logout', {
        headers: makeAuthenticatedRequestHeaders()
    });
    localStorage.removeItem(sessionTokenHeader);
}

export async function getDefaultLocationValues() {
    const res = await axios.get('/api/v1/types', {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function exportLocations(locations) {
    const locationIds = [];
    for (const location of locations) {
        locationIds.push(location.id);
    }

    const res = await axios.post("/api/v1/export/locations", {locationIds: locationIds}, {
        headers: makeAuthenticatedRequestHeaders()
    });

    return res.data;
}

export async function getImagesForLocationId(locationId) {
    const res = await axios.get(`/api/v1/locations/${locationId}/images`, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function uploadImagesForLocationId(locationId, formData) {
    await axios.post(`/api/v1/locations/${locationId}/images`, formData, {
        headers: makeAuthenticatedRequestHeaders()
    });
}

export async function deleteImage(locationId, imageId) {
    await axios.delete(`/api/v1/locations/${locationId}/images/${imageId}`, {
        headers: makeAuthenticatedRequestHeaders()
    });
}

export async function updateImage(image) {
    await axios.put(`/api/v1/locations/${image.locationId}/images/${image.id}`, {
        image: image
    }, {
        headers: makeAuthenticatedRequestHeaders()
    });
}

export async function getFilesForLocationId(locationId) {
    const res = await axios.get(`/api/v1/locations/${locationId}/files`, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function uploadFilesForLocationId(locationId, formData) {
    await axios.post(`/api/v1/locations/${locationId}/files`, formData, {
        headers: makeAuthenticatedRequestHeaders()
    });
}

export async function deleteFile(locationId, fileId) {
    await axios.delete(`/api/v1/locations/${locationId}/files/${fileId}`, {
        headers: makeAuthenticatedRequestHeaders()
    });
}

export async function getCurrentUser() {
    const res = await axios.get('/api/v1/users/whoami', {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function updateUser(userId, updateFields) {
    const res = await axios.put(`/api/v1/users/${userId}`, updateFields, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function createInvitationToken(tokenInfo) {
    const res = await axios.post(`/api/v1/invitations/token`, tokenInfo, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function createUserWithInvitationToken(tokenId, userInfo) {
    const res = await axios.post(`/api/v1/invitations/noauth/token/${tokenId}/consume`, userInfo);
    return res.data;
}

export async function createMultiUseInvitationCode(tokenInfo) {
    const res = await axios.post(`/api/v1/invitations/code`, tokenInfo, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function getTokenForMultiUseInvitationCode(multiUseInvitationCodeId) {
    const res = await axios.get(`/api/v1/invitations/noauth/code/${multiUseInvitationCodeId}/token`,);
    return res.data.invitationTokenId;
}

export async function getUsers() {
    const res = await axios.get(`/api/v1/users`, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function getUser(userId) {
    const res = await axios.get(`/api/v1/users/${userId}`, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function getEvents() {
    const res = await axios.get(`/api/v1/events`, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data.events;
}

export async function getPendingEvents() {
    const res = await axios.get(`/api/v1/events/pending`, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data.events;
}

export async function markEventsAsViewed(eventIds) {
    const res = await axios.post(`/api/v1/events/markviewed`, {
        eventIds: eventIds
    }, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function getComments(query) {
    const res = await axios.get(`/api/v1/comments`, {
        params: query,
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function updateComment(commentId, updateBody) {
    const res = await axios.put(`/api/v1/comments/${commentId}`, updateBody, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function deleteComment(commentId) {
    const res = await axios.delete(`/api/v1/comments/${commentId}`, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function createComment(comment) {
    const res = await axios.post(`/api/v1/comments`, comment, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function createEvent(event) {
    const res = await axios.post(`/api/v1/events`, event, {
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function shareLocationWithUser(locationId, userId) {
    const res = await axios.put(`/api/v1/locations/${locationId}/shares/${userId}`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res;
}

export async function unshareLocationWithUser(locationId, userId) {
    const res = await axios.delete(`/api/v1/locations/${locationId}/shares/${userId}`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res;
}

export async function getSharedUsersForLocation(locationId) {
    const res = await axios.get(`/api/v1/locations/${locationId}/shares`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data.sharedWithUsers;
}

export async function getCollections() {
    const res = await axios.get(`/api/v1/collections`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function addLocationToCollection(collectionId, locationId) {
    const res = await axios.put(`/api/v1/collections/${collectionId}/${locationId}`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function removeLocationFromCollection(collectionId, locationId) {
    const res = await axios.delete(`/api/v1/collections/${collectionId}/${locationId}`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function getCollectionById(collectionId) {
    const res = await axios.get(`/api/v1/collections/${collectionId}`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data;
}

export async function deleteCollectionById(collectionId) {
    await axios.delete(`/api/v1/collections/${collectionId}`,{
        headers: makeAuthenticatedRequestHeaders()
    });
}

export async function createCollection(collection) {
    await axios.post(`/api/v1/collections`, collection, {
        headers: makeAuthenticatedRequestHeaders()
    });
}

export async function updateCollection(collection) {
    await axios.put(`/api/v1/collections/${collection.id}`, collection, {
        headers: makeAuthenticatedRequestHeaders()
    });
}

export async function shareCollectionWithUser(collectionId, userId) {
    const res = await axios.put(`/api/v1/collections/${collectionId}/shares/${userId}`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res;
}

export async function unshareCollectionWithUser(collectionId, userId) {
    const res = await axios.delete(`/api/v1/collections/${collectionId}/shares/${userId}`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res;
}

export async function getSharedUsersForCollection(collectionId) {
    const res = await axios.get(`/api/v1/collections/${collectionId}/shares`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res.data.sharedWithUsers;
}

export async function likeLocation(locationId) {
    const res = await axios.put(`/api/v1/locations/${locationId}/likes`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res;
}

export async function unlikeLocation(locationId) {
    const res = await axios.delete(`/api/v1/locations/${locationId}/likes`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res;
}

export async function likeImage(locationId, imageId) {
    const res = await axios.put(`/api/v1/locations/${locationId}/images/${imageId}/likes`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res;
}

export async function unlikeImage(locationId, imageId) {
    const res = await axios.delete(`/api/v1/locations/${locationId}/images/${imageId}/likes`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res;
}

export async function likeComment(commentId) {
    const res = await axios.put(`/api/v1/comments/${commentId}/likes`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res;
}

export async function unlikeComment(commentId) {
    const res = await axios.delete(`/api/v1/comments/${commentId}/likes`,{
        headers: makeAuthenticatedRequestHeaders()
    });
    return res;
}

export async function mergeDuplicateLocations(primaryId, secondaryId) {
    const body = {
        primaryLocationId: primaryId,
        secondaryLocationId: secondaryId
    }
    const res = await axios.put(`/api/v1/locations/merge`, {
        headers: makeAuthenticatedRequestHeaders(),
        data: body
    });
    return res;
}
