import './CollectionsPage.css';
import ApplicationBar from '../ApplicationBar';
import { useEffect, useState } from 'react';
import { getCollections, exportLocations } from '../../backendclient';
import { Collections, Edit, Group, ImportExport } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import EditCollectionModal from '../EditCollectionModal';
import AddCollectionButton from '../AddCollectionButton';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import CollectionSharingModal from './CollectionSharingModal';

function CollectionsPage() {
    const [collections, setCollections] = useState(null);
    const [reloadToken, setReloadToken] = useState(0);
    const [editCollectionId, setEditCollectionId] = useState(null);
    const [shareModalCollectionId, setShareModalCollectionId] = useState(null);

    useEffect(() => {
        getCollections()
            .then(setCollections)
            .catch(console.log);
    }, [reloadToken]);

    async function exportCollection(collection) {
        const exportedData = await exportLocations(collection.locations);
        fileDownload(exportedData, `${collection.name}.gpx`);
    }

    function getCollectionCard(collection) {
        return <div className='CollectionCard' key={collection.id}>
            <Link className='CollectionCardLink' to={`/map?collectionId=${collection.id}`}>
                <Collections fontSize="large" style={{margin: "5px"}} />
                <Typography style={{flexGrow: 10, margin: "auto"}}>{collection.name} ({collection.locations.length} locations)</Typography>
            </Link>
            <Tooltip title="Share Collection"><IconButton className='ShareButton' onClick={() => setShareModalCollectionId(collection.id)}><Group /></IconButton></Tooltip>
            <Tooltip title="Export as GPX"><IconButton onClick={() => exportCollection(collection)}><ImportExport /></IconButton></Tooltip>
            <Tooltip title="Edit"><IconButton onClick={() => setEditCollectionId(collection.id)}><Edit /></IconButton></Tooltip>
        </div>
    }

    function getCollectionsList() {
        if (collections && collections.length < 1) {
            return <Typography>Looks like you don't have any collections yet...</Typography>
        }

        return collections.map(getCollectionCard);
    }

    return <div>
        <ApplicationBar />
        <div className='Container'>
            <div className='CollectionsPageTitle'>
                <h3>Manage Collections</h3>
            </div>
            {collections ? getCollectionsList() : "Loading..."}
            <AddCollectionButton style={{marginTop: "20px"}} onSave={() => setReloadToken(v => v+1)} />
        </div>
        <EditCollectionModal 
            open={editCollectionId} 
            collectionId={editCollectionId} 
            onCancel={() => setEditCollectionId(null)} 
            onSave={() => {setEditCollectionId(null); setReloadToken(v => v+1)}} />
        <CollectionSharingModal open={Boolean(shareModalCollectionId)} onClose={() => setShareModalCollectionId(null)} collectionId={shareModalCollectionId} />
    </div>
}

export default CollectionsPage;
