import ApplicationBar from '../ApplicationBar';
import InfoFooter from '../InfoFooter';
import './AboutPage.css';

function AboutPage() {
    return <div>
        <ApplicationBar />
        <InfoFooter fontSize="16px" />
    </div>
}

export default AboutPage;
