import CurrentUserContext from "../../contexts/CurrentUserContext";

function MessageGroupCard({currentUser, isSelected, messageGroup, onClick}) {
    if (!currentUser) return null;

    function hasUnreadmessages() {
        for (const message of messageGroup.messages) {
            if (message.readByUsers.filter(u => u.id === currentUser.id).length === 0 && message.authorId !== currentUser.id) {
                return true;
            }
        }
        return false;
    }

    function getDisplayName() {
        if (messageGroup.displayName) {
            return messageGroup.displayName;
        } else {
            return messageGroup.users.filter(u => u.id !== currentUser.id).map(u => u.username).reduce((p, v) => p + ", " + v);
        }
    }


    // TODO add icon for message group
    // TODO add way for user to leave message group
    // TODO add way for user to add another user to a message group
    return <div className={isSelected ? "MessageGroupCard HighlightedMessageGroupCard" : "MessageGroupCard"} onClick={onClick}>
        {hasUnreadmessages() ? <div className="MessageGroupUnreadDot" /> : null}
        <div className="MessageGroupDisplayName">
            {getDisplayName()}
            {messageGroup.description? <div className="MessageGroupDescription">{messageGroup.description}</div> : null}
        </div>
    </div>
}

function MessageGroupCardWrapper(props) {
    return <CurrentUserContext.Consumer>
        {currentUser => < MessageGroupCard currentUser={currentUser} {...props} />}
    </CurrentUserContext.Consumer>
}

export default MessageGroupCardWrapper;
