import { IconButton, Typography } from "@mui/material";
import IsoDate from '../IsoDate';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import { Delete } from "@mui/icons-material";
import Linkify from 'react-linkify/dist/components/Linkify';
import { deleteComment, likeComment, unlikeComment } from "../../backendclient";
import LikeButton from "../LikeButton";
import UsernameLink from "../UsernameLink";

function CommentContent({comment, currentUser, onChange}) {
    const dateDisplayOptions = {
        month: "long",
        day: "numeric",
        year: "numeric"
    };

    async function onDelete() {
        await deleteComment(comment.id);
        onChange();
    }

    return <div className='Comment'>
        <div className="CommentAuthor">
            <Typography variant="body2"><UsernameLink user={comment?.author} /></Typography>
            <div><Typography variant="caption"><IsoDate isoString={comment.createdAt} options={dateDisplayOptions} /></Typography></div>
        </div>
        <div className="CommentBody">
            <Linkify><Typography variant="body2">{comment.text}</Typography></Linkify>
        </div>
        <div className="CommentControls">
            <LikeButton 
                likedByUsers={comment.likedByUsers} 
                likeFunc={async () => await likeComment(comment.id)} 
                unlikeFunc={async () => await unlikeComment(comment.id)} 
                onChange={onChange}
                tooltipResourceName="Comment"
                size='small'
            />
            {currentUser.id === comment.authorId ? <IconButton size="small" onClick={onDelete}>
                <Delete fontSize="small" />
            </IconButton> : null }
        </div>
    </div>
}

function Comment(props) {
    return <CurrentUserContext.Consumer>
        {currentUser => <CommentContent currentUser={currentUser} {...props}/>}
    </CurrentUserContext.Consumer>
}

export default Comment;
