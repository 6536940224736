import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUsers } from '../../backendclient';
import DisplayWithPrivilege from '../DisplayWithPrivilege';

function UsernameDisplay({user}) {
    return <DisplayWithPrivilege privilege={"canEditUsers"} alternate={user.username}>
        <Link to={`/admin/user/${user.id}`}>{user.username}</Link>
    </DisplayWithPrivilege>
}

function UserList() {
    const [users, setUsers] = useState(null);

    useEffect(() => {
        getUsers()
            .then(setUsers)
            .catch(console.log);
    }, []);

    if (!users) {
        return "Loading...";
    }

    return (
        <div className='AdminPageSection'>
            <h3>Users</h3>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Invited By</TableCell>
                            <TableCell>Invited From Code</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => <TableRow key={user.id}>
                            <TableCell><UsernameDisplay user={user} /></TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{new Date(user.createdAt).toDateString()}</TableCell>
                            <TableCell>{user.invitedByUser ? user.invitedByUser.username : ""}</TableCell>
                            <TableCell>{user.invitedFromToken && user.invitedFromToken.MultiUseInvitationCode ? user.invitedFromToken.MultiUseInvitationCode.id : ""}</TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default UserList;
