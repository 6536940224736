import './EventPage.css';
import ApplicationBar from '../ApplicationBar';
import { useEffect, useState } from 'react';
import { getEvents, markEventsAsViewed } from '../../backendclient';
import EventPageContent from './EventPageContent';
import InfoFooter from '../InfoFooter';
import { isEventPending } from '../../utils.js';

function EventPage() {
    const [events, setEvents] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        getEvents()
            .then(setEvents)
            .catch(setError);
    }, []);

    useEffect(() => {
        if (!events) return;
        const pendingEvents = events.filter(isEventPending);
        if (pendingEvents.length > 0) {
            markEventsAsViewed(pendingEvents.map(e => e.id));
        }
    }, [events]);

    if (error) {
        return error.message;
    }

    return (
        <div>
            <ApplicationBar />
            
            <div className='Container'>
                <h3>Recent Events</h3>
                {events ? <EventPageContent events={events} /> : "Loading..."}
            </div>

            <InfoFooter />
        </div>
    );
}

export default EventPage;
