import React, { useEffect, useState } from 'react';
import { getDefaultLocationValues } from '../backendclient';
import CurrentUserContext from './CurrentUserContext';

const LocationTypes = React.createContext(null);

function LocationTypesProvider({currentUser, children}) {
    const [locationTypes, setLocationTypes] = useState(null);
    const [error, setError] = useState(null);

    // this effect loads location types
    useEffect(() => {
        getDefaultLocationValues()
            .then(setLocationTypes)
            .catch(setError);
    }, [currentUser]);

    return <LocationTypes.Provider value={{
        error: error,
        locationTypes: locationTypes,
    }}>
        {children}
    </LocationTypes.Provider>
}

function LocationTypesProviderWrapper(props) {
    return <CurrentUserContext.Consumer>
        {currentUserValue => <LocationTypesProvider currentUser={currentUserValue} {...props} />}
    </CurrentUserContext.Consumer>
}

const exports = {Provider: LocationTypesProviderWrapper, Consumer: LocationTypes.Consumer};;
export default exports;
