import { useEffect } from 'react'

function useDocumentTitle(title, prevailOnUnmount = false) {
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = "Gold Hills Trading Post";
    }
  }, [prevailOnUnmount])
}

export default useDocumentTitle
