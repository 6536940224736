import { PlaylistAdd } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

function LocationCollectionModalButton({onClick}) {
    return <Tooltip title="Add/Remove from Collection">
        <IconButton className='CollectionButton' onClick={onClick}><PlaylistAdd fontSize="small" /></IconButton>
    </Tooltip>
}

export default LocationCollectionModalButton;
