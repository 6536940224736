import { useState } from "react";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import Message from '../Message';
import { Button, TextField } from "@mui/material";
import './MessagesDisplay.css';


function MessagesDisplay({currentUser, messageGroup, onNewMessage, markMessageRead, isDesktop}) {
    const [newMessageBody, setNewMessageBody] = useState({});
    const [loading, setLoading] = useState(false);

    function canSendMessage() {
        return newMessageBody.text?.trim() && !loading;
    }

    async function onSubmitSendMessage(e) {
        try {
            e?.preventDefault();
            await setLoading(true);

            await onNewMessage(messageGroup, {
                authorId: currentUser.id,
                body: {
                    text: newMessageBody.text
                },
            });
            await setNewMessageBody({text: ""});
        } catch(err) {
            console.log("error sending message", err);
        } finally {
            setLoading(false);
        }
    }

    return <div className="FocusedMessageGroupDisplay">
        <div className="MessagesDisplaySection" id="messagesDisplaySection">
            {messageGroup?.messages.length === 0 ? <div className="NoMessagesTextContainer"><div className="NoMessagesText">This group doesn't have any messages yet...</div></div> : messageGroup?.messages.toReversed().map(message => <Message 
                message={message} 
                key={message.id} 
                currentUser={currentUser}
                markMessageRead={message => markMessageRead(messageGroup, message)}
            />)}
            <div className="MessagesEndRef" id="messagesEndRef" />
        </div>
        <form className="NewMessageInputSection" onSubmit={onSubmitSendMessage}>
            <TextField
                autoFocus={isDesktop}
                multiline
                rows={4}
                size="small"
                fullWidth
                onChange={e => setNewMessageBody(prev => ({...prev, text: e.target.value}))} value={newMessageBody.text || ""} 
                onKeyDown={e => {
                    if (!e.shiftKey && e.code === "Enter") {
                        onSubmitSendMessage(e);
                    }
                }}
                placeholder="Enter to send - shift enter for newline"
            />
            <Button type="submit" disabled={!canSendMessage()} variant="contained">Send</Button>
        </form>
    </div>
}

function MessagesDisplayWrapper(props) {
    return <CurrentUserContext.Consumer>
        {currentUser => <MessagesDisplay currentUser={currentUser} {...props} />}
    </CurrentUserContext.Consumer>
}

export default MessagesDisplayWrapper;
