import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { WEB_SOCKET_EVENT_TYPES } from '../sharedConstants';
import LocationTypesContext from './LocationTypesContext';
import CurrentUserContext from './CurrentUserContext';

const MessagesContext = React.createContext(null);

function getMessageGroupIdForMessage(message) {
    return message.destinationGlobal ? "global" : message.destinationLocationType || message.destinationMessageGroupId;
}

function MessagesContextProvider({children, currentUser, locationTypes}) {
    const [messageBuffer, setMessageBuffer] = useState([]);
    const [messageGroups, setMessageGroups] = useState({});
    const [webSocketUrl, setWebSocketUrl] = useState(null);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const { readyState, sendJsonMessage, getWebSocket} = useWebSocket(webSocketUrl, {
        onOpen: () => {
            console.log("connected to messages websocket");
        },
        onMessage: ({data}) => {
            const message = JSON.parse(data);
            if (message.eventType === WEB_SOCKET_EVENT_TYPES.newMessage) {
                const newMessageBody = {
                    ...message.data,
                    isUnread: message.data.readByUsers.filter(u => u.id === currentUser.id).length === 0 && message.data.authorId !== currentUser.id
                };
                setMessageBuffer(prev => ([...prev, newMessageBody]));
            } else if (message.eventType === WEB_SOCKET_EVENT_TYPES.newMessageGroup) {
                const messageGroup = message.data;

                setMessageGroups(prev => ({
                    ...prev,
                    [messageGroup.id]: {
                        ...messageGroup,
                        messages: [],
                        destinationMessageGroupId: messageGroup.id
                    },
                }));
            } else if (message.eventType === WEB_SOCKET_EVENT_TYPES.initialLoadComplete) {
                setInitialLoadComplete(true);
            }
        },
        shouldReconnect: (closeEvent) => true,
        retryOnError: (error) => true,
    });

    // send heartbeat while websocket is open
    useEffect(() => {
        function sendHeartbeat() {
            if (sendJsonMessage && readyState === 1) {
                sendJsonMessage({
                    eventType: WEB_SOCKET_EVENT_TYPES.heartbeat
                });
                setTimeout(sendHeartbeat, 10000);
            }
        }
        sendHeartbeat();
    }, [sendJsonMessage, readyState])

    useEffect(() => {
        if (!locationTypes || !currentUser || !getWebSocket) {
            getWebSocket()?.close();
            setMessageGroups({}); // to make sure we don't load messages multiple times
            return;
        }

        const messageGroupsForLocationTypes = {};
        Object.values(locationTypes).filter(locationType => locationType.hasMessageGroup).forEach(locationType => {
            messageGroupsForLocationTypes[locationType.type] = {
                id: locationType.type,
                displayName: locationType.type,
                description: "All users with access to the " + locationType.type  + " location type",
                destinationLocationType: locationType.type,
                messages: []
            };
        });

        setMessageGroups(prev => ({
            "global": {
                id: "global",
                displayName: "Global",
                description: "All users on Gold Hills",
                destinationGlobal: true,
                messages: []
            },
            ...messageGroupsForLocationTypes,
            ...prev
        }));

        const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
        setWebSocketUrl(`${protocol}//${window.location.host}/api/v1/messages?user=${currentUser.id}`);
    }, [locationTypes, currentUser, getWebSocket]);

    useEffect(() => {
        if (messageBuffer.length === 0) {
            return;
        }

        const messagesToGoBackOnBuffer = [];

        messageBuffer.forEach(message => {
            const messageGroupId = getMessageGroupIdForMessage(message);
            if (!messageGroupId || !messageGroups[messageGroupId]) {
                messagesToGoBackOnBuffer.push(message);
                return;
            }
            setMessageGroups(prev => ({
                ...prev,
                [messageGroupId]: {
                    ...prev[messageGroupId],
                    messages: [
                        ...prev[messageGroupId].messages,
                        message
                    ]
                }
            }));
        });

        setMessageBuffer(messagesToGoBackOnBuffer);
    }, [messageGroups, messageBuffer]);

    function getDestinationFieldsForMessageGroup(messageGroup) {
        return {
            destinationGlobal: messageGroup.destinationGlobal,
            destinationLocationType: messageGroup.destinationLocationType,
            destinationMessageGroupId: messageGroup.destinationMessageGroupId
        }
    }

    async function markMessageRead(messageGroup, message) {
        const messageGroupId = messageGroup.id;
        setMessageGroups(prev => ({
            ...prev,
            [messageGroupId]: {
                ...messageGroups[messageGroupId],
                messages: messageGroups[messageGroupId].messages.map(m => {
                    if (m.id === message.id) {
                        m.readByUsers.push(currentUser);
                        m.isUnread = false;
                    }
                    return m;
                }),
            },
        }));
        sendJsonMessage({
            eventType: WEB_SOCKET_EVENT_TYPES.messageRead,
            data: {
                messageId: message.id,
                userId: currentUser.id,
                ...getDestinationFieldsForMessageGroup(messageGroup)
            }
        });
    }

    return <MessagesContext.Provider value={{
        sendMessage: sendJsonMessage,
        isReady: readyState === 1,
        messageGroups: messageGroups,
        markMessageRead: markMessageRead,
        initialLoadComplete,
    }}>
        {children}
    </MessagesContext.Provider>
}

function MessagesContextProviderWrapper(props) {
    return <CurrentUserContext.Consumer>
        {currentUserValue => <LocationTypesContext.Consumer>
            {locationTypesContextValue => <MessagesContextProvider {...props} currentUser={currentUserValue} locationTypes={locationTypesContextValue.locationTypes} />}
        </LocationTypesContext.Consumer>}
    </CurrentUserContext.Consumer>
}

const exports = {Provider: MessagesContextProviderWrapper, Consumer: MessagesContext.Consumer};;
export default exports;
