import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router';
import './FoundPage.css';
import { getTokenForMultiUseInvitationCode } from '../../backendclient';

function FoundPage() {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [invitationCode, setInvitationCode] = useState("");

    async function onSubmit() {
        try {
            const tokenId = await getTokenForMultiUseInvitationCode(invitationCode)
            navigate(`/invitation?token=${tokenId}`)
        } catch (e) {
            setError(e);
        }
    }
    
    return (
        <div className='Container FoundPage'>
            <form onSubmit={(e) => {
                e.preventDefault();
            }}>
                <img alt="site logo" src='/Logo_Title.png' />
                <div>
                    <TextField label="Access Code" onChange={e => setInvitationCode(e.target.value)} />
                </div>
                <div>
                    <Button type='submit' disabled={invitationCode.length === 0} onClick={onSubmit} variant='contained'>Submit</Button>
                </div>
                <div>
                    {error ? <Typography color="error">{error.message}</Typography> : null}
                </div>
                <div className='ContactEmail'>
                    <Typography variant="caption">Having trouble? Contact us at <a href="mailto:admin@goldhillstradingpost.net">admin@goldhillstradingpost.net</a></Typography>
                </div>
            </form>
        </div>
    );
}

export default FoundPage;
