import { Typography } from '@mui/material';
import './InfoFooter.css';

function InfoFooter({fontSize}) {
    fontSize = fontSize || "12px";

    return <div className='InfoFooter'>
        <div><Typography fontSize={fontSize} variant='body2'>Gold Hills Trading Post - ©2024</Typography></div>
        <div><Typography fontSize={fontSize} variant='body2'><a href="mailto:admin@goldhillstradingpost.net">admin@goldhillstradingpost.net</a></Typography></div>
    </div>
}

export default InfoFooter;
