import { useEffect, useState } from 'react';
import { deleteImage, getImagesForLocationId, updateImage, uploadImagesForLocationId } from '../../backendclient';
import Typography from '@mui/material/Typography';
import { Button, Dialog, TextField } from '@mui/material';
import './ImageManagementModal.css';
import UsernameLink from '../UsernameLink';

function ImageManagementCard({image, locationId, onCaptionChange, onImageDelete}) {
    return <div className='ImageManagementCard'>
        <div className='Section' style={{width: "100px", textAlign: "center"}}>
            <img style={{maxWidth: "100px", maxHeight: "100px", margin: "auto"}} src={`/api/v1/locations/${locationId}/images/${image.id}?size=small`} alt="User Submitted" />
            <Typography variant='caption'>Submitted by: {image && image.creator ? <UsernameLink openInTab={true} user={image.creator} /> : "Unknown"}</Typography>
        </div>
        <div className='Section' style={{flexGrow: 10}}>
            <TextField fullWidth multiline defaultValue={image.caption} onChange={e => onCaptionChange(image.id, e)} label="Caption" />
        </div>
        <div className='Section'>
            <Button size="small" variant="contained" color="error" onClick={() => onImageDelete(image.id)}>Delete</Button>
        </div>
    </div>
}

function ImageManagementModal({open, onClose, locationId}) {
    const [images, setImages] = useState([]);
    const [error, setError] = useState(null);
    const [reloadToken, setReloadToken] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getImagesForLocationId(locationId)
            .then(setImages)
            .catch(setError);
    }, [reloadToken, locationId]);

    function onCaptionChange(imageId, e) {
        setImages(images => {
            for (const image of images) {
                if (image.id === imageId) {
                    image.caption = e.target.value;
                }
            }
            return images;
        });
    }

    async function onImageDelete(imageId) {
        await deleteImage(locationId, imageId);
        setImages(images => images.filter(image => image.id !== imageId));
    }

    async function onSave() {
        await setIsLoading(true);
        const updateImagePromises = [];
        for (const image of images) {
            updateImagePromises.push(updateImage(image));
        }
        await Promise.all(updateImagePromises);
        setIsLoading(false);
        onClose();
    }

    async function onCancel() {
        setReloadToken(t => t+1);
        onClose();
    }

    async function handleUpload(e) {
        try {
            const selectedFiles = e.target.files;

            if (!selectedFiles || selectedFiles.length === 0) {
                return;
            }

            await setIsLoading(true);
            await setError(null);

            const data = new FormData();
            for (const file of selectedFiles) {
                data.append('multi-files', file);
            }

            await uploadImagesForLocationId(locationId, data);

            setReloadToken(reloadToken => reloadToken + 1);
            setIsLoading(false);
        } catch(e) {
            setIsLoading(false);
            setError(e);
        }
    }

    return <Dialog fullWidth open={open}>
        <div className='ImageManagementModal'>
            <Typography variant="h6">Manage Images</Typography>
            
            {images.map((image) => <ImageManagementCard key={image.id} locationId={locationId} image={image} onCaptionChange={onCaptionChange} onImageDelete={onImageDelete} />)}

            <div>
                <input type="file" multiple name="multi-files" onChange={handleUpload}/>
                {error ? <Typography color="error">{error.message}</Typography> : null}
                {isLoading ? <Typography>Loading...</Typography> : null}
            </div>

            <div style={{display: "flex"}}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button style={{marginLeft: "auto"}} variant='contained' disabled={isLoading} onClick={onSave}>Save</Button>
            </div>
        </div>
    </Dialog>
}

export default ImageManagementModal;
