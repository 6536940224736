import Checkbox from '@mui/material/Checkbox';
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { createInvitationToken, createMultiUseInvitationCode, getDefaultLocationValues } from "../../backendclient";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import ApplicationBar from '../ApplicationBar';

function InviteUserPageContent({currentUser}) {
    const [defaultLocationValues, setDefaultLocationValues] = useState(null);
    const [userPrivileges, setUserPrivileges] = useState({
        "canCreateLocations": true,
        "canEditLocations": true,
        "canViewLocations": true,
        "canDeleteLocations": true,
        "canImportLocations": true,
        "canExportLocations": true
    });
    const [hiddenLocationTypes, setHiddenLocationTypes] = useState({
        "Cave": true
    });
    const [message, setMessage] = useState("");

    useEffect(() => {
        getDefaultLocationValues()
            .then(setDefaultLocationValues);
    }, []);

    function handleHiddenLocationTypesCheck(e) {
        setHiddenLocationTypes(hiddenLocationTypes => ({
                ...hiddenLocationTypes,
                [e.target.id]: e.target.checked
        }));
    }

    function handlePrivilegesCheck(e) {
        setUserPrivileges(userPrivileges => ({
                ...userPrivileges,
                [e.target.id]: e.target.checked
        }));
    }

    async function createToken() {
        const res = await createInvitationToken({
            privileges: userPrivileges,
            hiddenLocationTypes: hiddenLocationTypes
        });

        const link = `${window.location.origin}/invitation?token=${res.id}`;

        setMessage(<div>
            <Typography>One Time Invitation Link:</Typography>
            <a href={link}>{link}</a>
        </div>);
    }

    async function createCode() {
        const res = await createMultiUseInvitationCode({
            privileges: userPrivileges,
            hiddenLocationTypes: hiddenLocationTypes
        });

        setMessage(<div>
            <Typography>Mutli-Use Invitation Code:</Typography>
            {res.id}
        </div>);
    }

    if (!currentUser || !defaultLocationValues) {
        return "Loading...";
    }

    return (
        <div className="AdminPageSection">
            <h3>Create User Invite</h3>

            <div>
                <h5>Select location types that will be hidden from the user:</h5>
                {Object.keys(defaultLocationValues).map(locationType => <div key={locationType}>
                    <Checkbox id={locationType} onChange={handleHiddenLocationTypesCheck} size='small' checked={hiddenLocationTypes[locationType] || false}/> {locationType}
                </div>)}
            </div>

            <div>
                <h5>Select privileges for the user:</h5>
                {Object.keys(currentUser.privileges).map(privilege => <div key={privilege}>
                    <Checkbox id={privilege} onChange={handlePrivilegesCheck} size='small' checked={userPrivileges[privilege] || false} /> {privilege}
                </div>)}
            </div>
            
            <div style={{marginTop: "20px"}}>
                <Button onClick={createToken} variant="contained">Create One Time Invitation Link</Button>
            </div>

            <div style={{marginTop: "20px"}}>
                <Button onClick={createCode} variant="contained">Create Multi-Use Invitation Code</Button>
            </div>

            <div style={{marginTop: "20px"}}>
                {message ? message : null}
            </div>
        </div>
    );
}

function InviteUserPage() {
    return <div>
        <ApplicationBar />
        
        <div className='Container'>
            <CurrentUserContext.Consumer>
                {value => <InviteUserPageContent currentUser={value} />}
            </CurrentUserContext.Consumer>
        </div>
    </div>
}

export default InviteUserPage;
