import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import EditPage from "../EditPage";
import LocationPage from "../LocationPage";
import LoginPage from "../LoginPage";
import MapPage from "../MapPage";
import CurrentUserContext from '../../contexts/CurrentUserContext';
import './App.css';
import { getCurrentUser } from "../../backendclient";
import UserSettingsPage from "../UserSettingsPage";
import InvitationPage from "../InvitationPage/InvitationPage";
import AdminPage from "../AdminPage";
import EventPage from "../EventPage";
import EditUserPage from "../EditUserPage";
import InviteUserPage from "../InviteUserPage";
import AboutPage from "../AboutPage";
import FoundPage from "../FoundPage";
import CollectionsPage from "../CollectionsPage";
import LocationsContext from "../../contexts/LocationsContext";
import LocationTypesContext from "../../contexts/LocationTypesContext";
import MessagesContext from "../../contexts/MessagesContext";
import MessagesPage from "../MessagesPage";
import UserProfilePage from "../UserProfilePage";

const noAuthPaths = [
    "/login",
    "/invitation",
    "/found",
];

function isNoAuthPath(path) {
    for (const noAuthPath of noAuthPaths) {
        if (path.startsWith(noAuthPath)) {
            return true
        }
    }
    return false;
}

function App() {
    const [currentUser, setCurrentUser] = useState(null);
    const [reloadUserToken, setReloadUserToken] = useState(0);

    useEffect(() => {
        getCurrentUser()
            .then(setCurrentUser)
            .catch(error  => {
                if (error.response && error.response.status === 401 && !isNoAuthPath(window.location.pathname)) {
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    console.log(error);
                }
            });
    }, [reloadUserToken]);

    function onLogin() {
        setReloadUserToken(v => v+1);
    }

    return (
        <div className="App">
            <CurrentUserContext.Provider value={currentUser}>
                <LocationTypesContext.Provider>
                    <LocationsContext.Provider>
                        <MessagesContext.Provider>
                            <Routes>
                                <Route path="/login" element={<LoginPage onLogin={onLogin} />} />
                                <Route path="/map" element={<MapPage />} />
                                <Route path="/edit/:locationId" element={<EditPage />} />
                                <Route path="/location/:locationId" element={<LocationPage />} />
                                <Route path="/usersettings" element={<UserSettingsPage />} />
                                <Route path="/invitation" element={<InvitationPage onLogin={onLogin}/>} />
                                <Route path="/collections" element={<CollectionsPage />} />
                                <Route path="/found" element={<FoundPage />} />
                                <Route path="/admin/user/:userId" element={<EditUserPage />} />
                                <Route path="/admin/invite" element={<InviteUserPage />} />
                                <Route path="/admin" element={<AdminPage />} />
                                <Route path="/events" element={<EventPage />} />
                                <Route path="/about" element={<AboutPage />} />
                                <Route path="/messages/:messageGroupId?" element={<MessagesPage />} />
                                <Route path="/user/:userId" element={<UserProfilePage />} />
                                <Route path="*" element={<Navigate to="/map" />} />
                            </Routes>
                        </MessagesContext.Provider>
                    </LocationsContext.Provider>
                </LocationTypesContext.Provider>
            </CurrentUserContext.Provider>
        </div>
    );
}

export default App;
