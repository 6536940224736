import './EditUserPage.css';
import ApplicationBar from '../ApplicationBar';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { getDefaultLocationValues, getUser, updateUser } from '../../backendclient';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import { Button, Checkbox, TextField } from '@mui/material';
import DisplayWithPrivilege from '../DisplayWithPrivilege';

function EditUserPageContent({userId, currentUser}) {
    const [user, setUser] = useState(null);
    const [defaultLocationValues, setDefaultLocationValues] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getUser(userId)
            .then(setUser)
            .catch(console.log);
    }, [userId]);

    useEffect(() => {
        getDefaultLocationValues()
            .then(setDefaultLocationValues);
    }, []);

    function handleHiddenLocationTypesCheck(e) {
        setUser(user => ({
            ...user,
            hiddenLocationTypes: {
                ...user.hiddenLocationTypes,
                [e.target.id]: e.target.checked
            }
        }));
    }

    function handlePrivilegesCheck(e) {
        setUser(user => ({
            ...user,
            privileges: {
                ...user.privileges,
                [e.target.id]: e.target.checked
            }
        }));
    }

    async function onSave() {
        await setLoading(true);
        await updateUser(user.id, user);
        navigate("/admin");
        await setLoading(false);
    }

    function onCancel() {
        navigate("/admin");
    }
    
    if (!user || !currentUser || !defaultLocationValues) {
        return "Loading...";
    }

    // compute the list of privileges that we can edit on the user
    // this is a combination of our current users privileges and the user's privileges
    const availablePrivileges = {};
    Object.keys(currentUser.privileges).forEach(p => {
        availablePrivileges[p] = availablePrivileges[p] || false;
    });
    Object.keys(user.privileges).forEach(p => {
        availablePrivileges[p] = availablePrivileges[p] || true;
    });

    return <div>
        <h3>Editing user {user.username}</h3>

        <div>
            <h5>Select location types that will be hidden from the user:</h5>
            {Object.keys(defaultLocationValues).map(locationType => <div key={locationType}>
                <Checkbox onChange={handleHiddenLocationTypesCheck} id={locationType} size='small' checked={user.hiddenLocationTypes[locationType] || false}/> {locationType}
            </div>)}
        </div>

        <div>
            <h5>Select privileges for the user:</h5>
            {Object.keys(availablePrivileges).map(privilege => <div key={privilege}>
                <Checkbox onChange={handlePrivilegesCheck} id={privilege} size='small' checked={user.privileges[privilege] || false} /> {privilege}
            </div>)}
        </div>

        <DisplayWithPrivilege privilege="isSuperUser">
            <div>
                <h5>Set user password:</h5>
                <TextField onChange={e => setUser(v => ({...v, password: e.target.value || undefined}))} />
            </div>
        </DisplayWithPrivilege>

        {loading ? "loading..." : <div className='EditUsersButtons'>
            {user.password !== undefined ? "User password will be updated" : null}
            <Button variant="contained" onClick={onSave}>Save</Button>
            <Button variant="outlined" onClick={onCancel}>Cancel</Button>
        </div>}
    </div>
}

function EditUserPage() {
    const params = useParams();
    const userId = params.userId;
    return (
        <div>
            <ApplicationBar />
            
            <div className='Container'>
                <CurrentUserContext.Consumer>
                    {currentUser => <EditUserPageContent userId={userId} currentUser={currentUser} />}
                </CurrentUserContext.Consumer>
            </div>
        </div>
    );
}

export default EditUserPage;
