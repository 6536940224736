import L from 'leaflet';

const addLocationIcon = new L.Icon({
    iconUrl: '/icons/marker-icon-red.png',
    iconAnchor: [13, 41],
    popupAnchor: [0, -35]
});

const defaultIcon = new L.Icon({
    iconUrl: '/icons/marker-icon-blue.png',
    iconAnchor: [13, 41],
    popupAnchor: [0, -35]
});

const groupIconsByCount = [...Array(50).keys()].map(i => new L.Icon({
    iconUrl: `/icons/group-icon-${i}.png`,
    iconAnchor: [16, 16],
}));

const groupOverflowIcon = new L.Icon({
    iconUrl: `/icons/group-icon-50+.png`,
    iconAnchor: [16, 16],
});

const locationTypeIcons = {
    "Mine": new L.Icon({
        iconUrl: '/icons/marker-mine.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Camp": new L.Icon({
        iconUrl: '/icons/marker-camp.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Point of Interest": new L.Icon({
        iconUrl: '/icons/marker-point-of-interest.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Cave": new L.Icon({
        iconUrl: '/icons/marker-cave.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Rock Hounding Site": new L.Icon({
        iconUrl: '/icons/marker-rock-hounding.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Structure": new L.Icon({
        iconUrl: '/icons/marker-ruins.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Hot Springs": new L.Icon({
        iconUrl: '/icons/marker-hot-springs.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Aviation Crash Site": new L.Icon({
        iconUrl: '/icons/marker-crash.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Native American Site": new L.Icon({
        iconUrl: '/icons/marker-native-american.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Rock Art": new L.Icon({
        iconUrl: '/icons/marker-rock-art.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Lookout": new L.Icon({
        iconUrl: '/icons/marker-lookout.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Ghost Town": new L.Icon({
        iconUrl: '/icons/marker-ghost-town.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    }),
    "Hiking Trail": new L.Icon({
        iconUrl: '/icons/marker-hike.png',
        iconAnchor: [13, 41],
        popupAnchor: [0, -35]
    })
};

export {
    addLocationIcon,
    defaultIcon,
    locationTypeIcons,
    groupIconsByCount,
    groupOverflowIcon
};
