import { useEffect, useState } from 'react';
import { getImagesForLocationId } from '../../backendclient';
import './LocationImagePreview.css';

function LocationImagePreview({locationId, className}) {
    const [images, setImages] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        getImagesForLocationId(locationId)
            .then(setImages)
            .catch(setError);
    }, [locationId]);

    if (error) {
        console.log(`Error loading images for location ${locationId}`);
        console.log(error);
        return null;
    }

    if (!images) {
        return null;
    }

    if (images.length === 0) {
        return null;
    }

    const activeImage = images[0];

    return (
        <div className={className ? 'LocationImagePreview ' + className : 'LocationImagePreview'}> 
            <img src={`/api/v1/locations/${locationId}/images/${activeImage.id}?size=small`} alt="User Submitted" />
        </div>
    );
}

export default LocationImagePreview;
