import { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet'
import { MenuItem, Select } from '@mui/material';
import leaflet from 'leaflet';
import './Map.css';

const mapLayers = [
    {name: "Google Hybrid", url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'},
    {name: "USGS Topo", url: "https://caltopo.com/tile/t/{z}/{x}/{y}.png"},
    {name: "Caltopo", url: "https://caltopo.com/tile/mbt/{z}/{x}/{y}.png"},
    {name: "Forest Service", url: "https://caltopo.com/tile/f16a/{z}/{x}/{y}.png"},
];

function getMapLayerIndexFromLocalStorage() {
    const savedIndex = parseInt(localStorage.getItem("MAP_LAYER") || "0");
    if (savedIndex >= mapLayers.length) {
        return 0;
    }
    return savedIndex;
}

function Map({children, zoom, center, style, className, onMove, fitBounds}) {
    const [activeLayer, setActiveLayer] = useState(getMapLayerIndexFromLocalStorage());
    const tileLayerRef = useRef(null);
    const mapRef = useRef(null);

    function mapLayerKeypressListener(e) {
        if (!e.ctrlKey) {
            return;
        }
        if (e.key === "]") {
            setActiveLayer(v => v < mapLayers.length -1 ? v+1 : 0);
        }
        if (e.key === "[") {
            setActiveLayer(v => v === 0 ? mapLayers.length - 1 : v-1);
        }
    }

    useEffect(() => {
        if (tileLayerRef.current) {
            tileLayerRef.current.setUrl(mapLayers[activeLayer].url);
        }

        localStorage.setItem("MAP_LAYER", activeLayer);
    }, [activeLayer]);

    useEffect(() => {
        const setOnMove = () => {
            if (mapRef.current) {
                mapRef.current.on('move', onMove);
            } else {
                // retry if the map ref isn't initialized yet
                setTimeout(setOnMove, 200);
            }
        };
        setOnMove();
    }, [onMove]);

    useEffect(() => {
        if (!mapRef.current) return;
        mapRef.current.flyTo(new leaflet.LatLng(center[0], center[1]), 16);
    }, [center]);

    useEffect(() => {
        if (!mapRef.current || !fitBounds) return;
        mapRef.current.flyToBounds(fitBounds, {maxZoom: 16, paddingTopLeft: [200, 0]});
    }, [fitBounds]);

    useEffect(() => {
        document.addEventListener("keydown", mapLayerKeypressListener);
        return () => {
            document.removeEventListener("keydown", mapLayerKeypressListener);
        }
    }, []);

    return (
        <MapContainer 
            attributionControl={false} 
            ref={mapRef} 
            className={className} 
            style={{height: "100%", ...style}} 
            zoom={zoom} scrollWheelZoom={true} 
            center={center} 
            zoomControl={false}
        >
            <TileLayer
                url={mapLayers[activeLayer].url}
                maxZoom= {20}
                subdomains={['mt1','mt2','mt3']}
                ref={tileLayerRef}
            />
            {children}
            <Select className='MapTypeSelection' size='small' value={activeLayer} onChange={(e) => setActiveLayer(e.target.value)}>
                {mapLayers.map((layer, index) => <MenuItem key={layer.url} value={index}>{layer.name}</MenuItem>)}
            </Select>
        </MapContainer>
    );
}

export default Map;
