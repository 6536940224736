import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useState } from "react";
import IsoDate from "../IsoDate/IsoDate";
import EventMessage from "../EventMessage";
import { Link } from "react-router-dom";
import { isEventPending } from "../../utils.js";

function Event({event, isPending}) {
    return <div className="Event" style={{
        backgroundColor: "rgb(243, 243, 243)",
        padding: "5px",
        margin: "5px",
        borderRadius: "5px"
    }}>
        {isPending ? <Typography variant="caption">New</Typography> : null}
        <EventMessage event={event} LinkComponent={Link} />
        <Typography variant="caption"><IsoDate isoString={event.createdAt} /></Typography>
    </div>
}

function EventGroup({key, events, isPending}) {
    const [isExpanded, setIsExpanded] = useState(false);

    const eventGroupMessageStrategies = {
        locationAdded: events => <span>{events[0].OriginatingUser.username} added {events.length} new locations</span>,
        locationModified: events => <span>{events[0].OriginatingUser.username} updated {events.length} locations</span>,
        locationImageAdded: events => <span>{events[0].OriginatingUser.username} added photos to {events.length} locations</span>,
    };

    if (events.length === 1) {
        return <Event event={events[0]} isPending={isPending} />
    }

    return <div className="Event">
        {isPending ? <Typography variant="caption">New</Typography> : null}
        <div style={{display: "flex"}}>
            <IconButton onClick={() => setIsExpanded(v => !v)}>{isExpanded ? <UnfoldLess /> : <UnfoldMore />}</IconButton>
            <Typography style={{margin: "auto", flexGrow: 10}}>{eventGroupMessageStrategies[events[0].type](events)}</Typography>
        </div>
        <div>{isExpanded ? events.map(e => <Event key={e.id} event={e} isPending={isEventPending(e)} />) : null}</div>
        {isExpanded ? null : <Typography variant="caption"><IsoDate isoString={events[0].createdAt} /></Typography>}
    </div>  
}

function EventPageContent({events}) {
    const groupableLocationTypes = [
        "locationAdded",
        "locationModified",
        "locationImageAdded"
    ];

    const eventGroups = [];
    events.forEach(e => {
        if (eventGroups.length === 0) {
            eventGroups.push([e]);
        } else {
            const lastGroup = eventGroups[eventGroups.length - 1];
            const lastGroupUser = lastGroup[0].originatingUser;
            const lastGroupType = lastGroup[0].type;
            if (lastGroupUser === e.originatingUser && lastGroupType === e.type && groupableLocationTypes.includes(e.type)) {
                lastGroup.push(e);
            } else {
                eventGroups.push([e]);
            }
        }
    });

    if (events.length === 0) {
        return <div>
            There aren't any recent events...
        </div>
    }

    return <div>
        {eventGroups.map(eventGroup => {
            const isPending = eventGroup.filter(isEventPending).length > 0;

            return <EventGroup 
                isPending={isPending} 
                key={eventGroup.id} 
                events={eventGroup} />
        })}
    </div>
}

export default EventPageContent;
