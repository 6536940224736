import { Link } from "react-router-dom";
import './UsernameLink.css';

function UsernameLink({user, userId, username, openInTab}) {
    const effectiveUserId = user?.id || userId;
    const effectiveUsername = user?.username || username;
    return <Link target={openInTab ? "_blank" : null} className="UsernameLink" to={`/user/${effectiveUserId}`}>{effectiveUsername}</Link>
}

export default UsernameLink;
