import { useEffect, useState } from "react";
import { getUsers } from '../../backendclient';
import { Autocomplete, TextField } from "@mui/material";
import CurrentUserContext from "../../contexts/CurrentUserContext";

function UserSelect({currentUser, className, onSelectedUsersChange, label}) {
    const [availableUsers, setAvailableUsers] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function loadUsers() {
            try {
                const users = await getUsers();
                setAvailableUsers(users.filter(u => u.id !== currentUser.id));
            } catch(err) {
                setError(err);
                console.log(err);
            }
        }
        loadUsers();
    }, [currentUser]);

    function onChange(e, newValue) {
        onSelectedUsersChange(newValue);
    }

    if (!availableUsers || !currentUser) {
        return "Loading..."
    }

    return <div className={className}>
        <Autocomplete
            multiple
            filterSelectedOptions
            options={availableUsers}
            getOptionLabel={u => u.username}
            onChange={onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label || "Select Users"}
                />
            )}
        />
        {error ? <div>{error.message}</div> : null}
    </div>
}

function UserSelectWrapper(props) {
    return <CurrentUserContext.Consumer>
        {currentUser => <UserSelect currentUser={currentUser} {...props} />}
    </CurrentUserContext.Consumer>
}

export default UserSelectWrapper;
