import ApplicationBar from "../ApplicationBar";
import MessagesContext from '../../contexts/MessagesContext';
import CurrentUserContext from "../../contexts/CurrentUserContext";
import './MessagesPage.css';
import { useEffect, useState } from "react";
import MessageGroupList from "./MessageGroupList";
import MessagesDisplay from "./MessagesDisplay";
import { useNavigate, useParams } from "react-router";
import { WEB_SOCKET_EVENT_TYPES } from "../../sharedConstants";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
const uuid = require('uuid');

function getDestinationFieldsForMessageGroup(messageGroup,) {
    return {
        destinationGlobal: messageGroup.destinationGlobal,
        destinationLocationType: messageGroup.destinationLocationType,
        destinationMessageGroupId: messageGroup.destinationMessageGroupId
    }
}

function MessagesPage({currentUser, isConnected, sendMessage, messageGroups, markMessageRead, initialLoadComplete}) {
    const params = useParams();
    const navigate = useNavigate();
    const selectedMessageGroupId = params.messageGroupId;
    const [sortedMessageGroups, setSortedMessageGroups] = useState(null);
    const selectedMessageGroup = messageGroups && selectedMessageGroupId ? messageGroups[selectedMessageGroupId] : undefined;
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 800);
    
    useEffect(() => {
        function handleResize() {
            setIsDesktop(window.innerWidth > 800);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    function setSelectedMessageGroupId(messageGroupId) {
        navigate(`/messages/${messageGroupId}`);
    }

    async function onNewMessageGroup(users) {
        const usersForNewMessageGroup = [currentUser, ...users];
        const matchingMessageGroups = Object.values(messageGroups).filter(messageGroup => {
            if (!messageGroup.users || messageGroup.users.length !== usersForNewMessageGroup.length) {
                return false;
            }
            for (const user of usersForNewMessageGroup) {
                if (messageGroup.users.filter(u => u.id === user.id).length === 0) {
                    return false;
                }
            }
            return true;
        });
        
        if (matchingMessageGroups.length > 0) {
            setSelectedMessageGroupId(matchingMessageGroups[0].id);
            return;
        }
        
        const newMessageGroupId = uuid.v4();
        await sendMessage({
            eventType: WEB_SOCKET_EVENT_TYPES.newMessageGroup,
            data: {
                id: newMessageGroupId,
                users: usersForNewMessageGroup
            }
        });
        setSelectedMessageGroupId(newMessageGroupId);
    }

    async function onNewMessage(messageGroup, message) {
        await sendMessage({
            eventType: WEB_SOCKET_EVENT_TYPES.newMessage,
            data: {
                ...message,
                ...getDestinationFieldsForMessageGroup(messageGroup)
            }
        });
    }

    function MessagesPageContent() {
        if (isDesktop) {
            return <div className='MessagesPage'>
                <MessageGroupList
                    onNewMessageGroup={onNewMessageGroup}
                    selectedMessageGroupId={selectedMessageGroupId}
                    onMessageGroupSelect={mg => setSelectedMessageGroupId(mg.id)} messageGroups={sortedMessageGroups} />
                <MessagesDisplay isDesktop={isDesktop} markMessageRead={markMessageRead} onNewMessage={onNewMessage} messageGroup={selectedMessageGroup} />
            </div>
        } else if (selectedMessageGroup) {
            return <div className='MessagesPage MessagesPageMobile'>
                <MessagesDisplay isDesktop={isDesktop} markMessageRead={markMessageRead} onNewMessage={onNewMessage} messageGroup={selectedMessageGroup} />
            </div> 
        } else {
            return <div className='MessagesPage'>
                <MessageGroupList 
                    onNewMessageGroup={onNewMessageGroup} 
                    selectedMessageGroupId={selectedMessageGroupId} 
                    onMessageGroupSelect={mg => setSelectedMessageGroupId(mg.id)} 
                    messageGroups={sortedMessageGroups} />
            </div>
        }
    }
    
    // this effect handles sorting message groups so the most recently active is displayed first
    useEffect(() => {
        if (!messageGroups) {
            return;
        }
        setSortedMessageGroups(Object.values(messageGroups).sort((a, b) => {
            if (a.messages.length === 0) {
                return 1
            }
            if (b.messages.length === 0) {
                return -1
            }
            return a.messages[a.messages.length-1].createdAt < b.messages[b.messages.length-1].createdAt ? 1 : -1;
        }));
    }, [messageGroups]);

    if (!isConnected || !initialLoadComplete) {
        return <div>Loading...</div>;
    }

    return <>
        <ApplicationBar>
            {selectedMessageGroup && !isDesktop ? <div className="MessagesDisplayBackArrowContainer">
                <IconButton style={{textDecoration: "none", color: "unset"}} onClick={() => navigate("/messages")}><ArrowBack /></IconButton>
            </div> : undefined}
        </ApplicationBar>
        
        {MessagesPageContent()}
    </>
}

function MessagesPageWrapper(props) {
    return <CurrentUserContext.Consumer>
        {currentUserContextValue => <MessagesContext.Consumer>
            {messagesContextValue => <MessagesPage
                currentUser={currentUserContextValue}
                isConnected={messagesContextValue.isReady}
                sendMessage={messagesContextValue.sendMessage} 
                messageGroups={messagesContextValue.messageGroups}
                markMessageRead={messagesContextValue.markMessageRead}
                initialLoadComplete={messagesContextValue.initialLoadComplete}
                {...props}/>}
        </MessagesContext.Consumer>}
    </CurrentUserContext.Consumer>
}

export default MessagesPageWrapper;
