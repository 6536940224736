import {  useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Dialog, TextField } from '@mui/material';
import './EditCollectionModal.css';
import { createCollection, deleteCollectionById, getCollectionById, updateCollection } from '../../backendclient';

function EditCollectionModal({collectionId, open, onSave, onCancel}) {
    const [isLoading, setIsLoading] = useState(false);
    const [collection, setCollection] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (collectionId) {
            getCollectionById(collectionId)
                .then(setCollection)
                .catch(console.log);
        } else {
            setCollection({
                name: ""
            });
        }
    }, [collectionId]);

    async function handleSave() {
        try {
            await setIsLoading(true);

            if (collectionId) {
                await updateCollection(collection);
            } else {
                await createCollection(collection);
            }
    
            onSave();
        } catch(e) {
            console.log(e);
            setError(e);
        } finally {
            setCollection(null);
            setIsLoading(false);
        }
    }

    async function handleDelete() {
        try {
            await setIsLoading(true);
            await deleteCollectionById(collectionId);
            onSave();
        } catch(e) {
            console.log(e);
            setError(e);
        } finally {
            setCollection(null);
            setIsLoading(false);
        }
    }

    return <Dialog fullWidth open={open} onClose={onCancel}>
        <div className='EditCollectionModal'>
            <Typography variant="h6">{collectionId ? "Edit Collection" : "New Collection"}</Typography>
            {collectionId ? <Typography variant="caption">You can add locations to a collection from the location page.</Typography> : null}

            <div className='EditCollectionFields'>
                <Typography variant="subtitle2">Name</Typography>
                <TextField fullWidth value={collection ? collection.name : ""} onChange={e => setCollection(v => ({...v, name: e.target.value}))} />
                {error ? <Typography color="error">{error.message}</Typography> : null}
                {isLoading || !collection ? <Typography>Loading...</Typography> : null}
            </div>

            <div style={{display: "flex", marginTop: 10}}>
                <Button style={{marginRight: "auto"}} onClick={onCancel}>Cancel</Button>
                {collectionId ? <Button onClick={handleDelete} variant="contained" color="warning" style={{marginLeft: "auto"}}>Delete</Button> : null}
                <Button style={{marginLeft: "5px"}} variant='contained' disabled={isLoading || !collection} onClick={handleSave}>Save</Button>
            </div>
        </div>
    </Dialog>
}



export default EditCollectionModal;
