import { Button, Dialog, Typography } from "@mui/material";
import UserSelect from '../../UserSelect';
import './NewMessageGroupModal.css';
import { useState } from "react";

function NewMessageGroupModal({open, onSave, onClose}) {
    const [selectedUsers, setSelectedUsers] = useState([]);

    async function onSaveWrapper() {
        await onSave(selectedUsers);
        onClose();
    }

    return <Dialog fullWidth open={open} onClose={onClose}>
        <div className="NewMessageGroupModal">
            <Typography variant="h6">New Message</Typography>
            <UserSelect 
                className="NewMessageGroupModalUserSelect"
                onSelectedUsersChange={setSelectedUsers}
                label="Recipients"
            />

            <div className="NewMessageGroupModalButtons">
                <Button id="cancelbutton" variant="outlined" onClick={onClose}>Cancel</Button>
                <Button id="createbutton" variant="contained" disabled={selectedUsers.length === 0} onClick={onSaveWrapper}>Create</Button>
            </div>
        </div>
    </Dialog>
}

export default NewMessageGroupModal;