import './AdminPage.css';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { getLocationById, mergeDuplicateLocations } from '../../backendclient';
import LocationContext from '../../contexts/LocationsContext';

const ConfirmationPopup = ({ message, onConfirm, onCancel, showPrivacyWarning }) => {
    return (
      <div className="overlay">
        <div className="confirmation-popup">
          {showPrivacyWarning && <p style={{color: 'red', fontWeight: 'bold', size: '14px'}}>WARNING: Privacy or Sensitivity Settings differ between locations.</p>}  
          <p style={{ fontWeight: 'bold'}}>{message}</p>
          <div className="button-container">
            <button onClick={onConfirm}>Merge</button>
            <button onClick={onCancel}>Cancel</button>
          </div>
        </div>
      </div>
    );
};

function MergeLocations() {

    const [primaryLocationId, setPrimaryLocationId] = useState("");
    const [secondaryLocationId, setSecondaryLocationId] = useState("");

    const [privacyWarning, setPrivacyWarning] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");

    async function onMerge() {
        try {
            const primaryLoc = await getLocationById(primaryLocationId);
            const secondaryLoc = await getLocationById(secondaryLocationId);
            if ((primaryLoc.isSensitive !== secondaryLoc.isSensitive || 
                primaryLoc.isPrivate !== secondaryLoc.isPrivate)) {
                setPrivacyWarning(true);
            } else {
                setPrivacyWarning(false);
            }
            setShowConfirmation(true);
            setError(false);
            const msg = "You are merging \"" + secondaryLoc.name + "\" into \"" + primaryLoc.name + "\", deleting \"" + secondaryLoc.name + "\"";
            setPopupMessage(msg);
        } catch {
            setSuccess(false);
            setError(true);
        }
    }

    async function doMerge() {
        try {
            const res = await mergeDuplicateLocations(primaryLocationId, secondaryLocationId);
            if (res.status === 204) {
                setError(false);
                setSuccess(true);
            } else {
                throw new Error();
            }
            setShowConfirmation(false);
            setPrimaryLocationId("");
            setSecondaryLocationId("");
            setPrivacyWarning(false);
        } catch {
            setError(true);
            setSuccess(false);
            setShowConfirmation(false);
        }
    }

    function cancelMerge() {
        setShowConfirmation(false);
    }
    
    return (
    <div>
        <h1>Merge Locations</h1>
        <h3>All location info merged into primary location. Secondary location deleted</h3>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '15px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px'}}>
                <h4>Primary Location Id</h4>
                <TextField size="small" style={{ width: '375px' }} onChange={(e) => {setPrimaryLocationId(e.target.value); setSuccess(false);}} value={primaryLocationId} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px'}}>
                <h4>Secondary Location Id</h4>
                <TextField size="small" style={{ width: '375px' }} onChange={(e) => {setSecondaryLocationId(e.target.value); setSuccess(false);}} value={secondaryLocationId} />
            </div>
        </div>
        <Button style={{marginTop: "15px"}} disabled={primaryLocationId === "" || secondaryLocationId === ""} variant="contained" onClick={onMerge}>Merge</Button>
        {error && <span style={{ marginLeft: "20px", fontWeight: 'bold', color: 'red'}}>ERROR: Location Ids may not be valid</span>}
        {success && <span style={{ marginLeft: "20px", fontWeight: 'bold', color: 'green'}}>SUCCESS: Locations Merged</span>}
        <div>
        {showConfirmation && (
            <ConfirmationPopup
          message={popupMessage}
          onConfirm={doMerge}
          onCancel={cancelMerge}
          showPrivacyWarning={privacyWarning}
            />
        )}
        </div>
    </div>
    );
}

function MergeLocationsContextWrapper() {
    return <LocationContext.Consumer>
        {({reloadLocations}) => <MergeLocations onChange={reloadLocations} />}
    </LocationContext.Consumer>;
}

export default MergeLocationsContextWrapper;