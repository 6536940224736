import { useEffect, useState } from 'react';
import { deleteFile, getFilesForLocationId, uploadFilesForLocationId } from '../../backendclient';
import Typography from '@mui/material/Typography';
import './LocationFiles.css';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

function LocationFiles({locationId, className, canEdit}) {
    const [files, setFiles] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [reloadToken, setReloadToken] = useState(0);

    useEffect(() => {
        getFilesForLocationId(locationId)
            .then(setFiles)
            .catch(setError);
    }, [locationId, reloadToken]);

    if (error) {
        return <Typography color="error">{error.message}</Typography>
    }

    if (!files) {
        return <div className='LocationFiles'><Typography>Loading...</Typography></div>
    }

    function renderFile(file) {
        return <div className='File'>
            <Link className='FileName' target="_blank" to={`/api/v1/locations/${locationId}/files/${file.id}`} download>{file.fileName}</Link>
            {canEdit ? <IconButton className="DeleteButton" onClick={() => handleDelete(file)}><Delete /></IconButton> : null }
        </div>
    }

    async function handleUpload(e) {
        try {
            const selectedFiles = e.target.files;

            if (!selectedFiles || selectedFiles.length === 0) {
                return;
            }

            await setIsLoading(true);

            const data = new FormData();
            for (const file of selectedFiles) {
                data.append('multi-files', file);
            }

            await uploadFilesForLocationId(locationId, data);

            setReloadToken(reloadToken => reloadToken + 1);
            setIsLoading(false);
        } catch(e) {
            setIsLoading(false);
            setError(e);
        }
    }

    async function handleDelete(file) {
        await deleteFile(locationId, file.id);
        setReloadToken(reloadToken => reloadToken + 1);
    } 

    return (
        <div className={className ? 'LocationFiles ' + className : 'LocationFiles'}> 
            <Typography variant="h6">Files</Typography>

            {files.length === 0 ? <Typography variant="caption">No Files</Typography> : files.map(renderFile)}

            {canEdit ? <div className="UploadFileInput">
                <input type="file" multiple name="multi-files" onChange={handleUpload}/>
                {isLoading ? <div><Typography variant="caption">Loading...</Typography></div> : null}
            </div> : null}
        </div>
    );
}

export default LocationFiles;
