import { useEffect, useState } from 'react';
import { getCollectionById, getSharedUsersForCollection, shareCollectionWithUser, unshareCollectionWithUser } from '../../backendclient';
import SharingModal from '../SharingModal';

function CollectionSharingModal({open, onClose, collectionId}) {
    const [collection, setCollection] = useState(null);

    useEffect(() => {
        if (!collectionId) return;
        getCollectionById(collectionId)
            .then(setCollection)
            .catch(console.log);
    }, [collectionId]);

    async function share(userId) {
        return await shareCollectionWithUser(collectionId, userId);
    }

    async function unshare(userId) {
        return await unshareCollectionWithUser(collectionId, userId);
    }

    async function getSharedUsers() {
        return await getSharedUsersForCollection(collectionId);
    }

    return <SharingModal
        open={open}
        onClose={onClose}
        resourceDisplayName={collection ? collection.name : ""}
        shareWithUser={share} 
        unshareWithUser={unshare}
        getUsersWithAccess={getSharedUsers} />
}

export default CollectionSharingModal;
