import { Typography } from "@mui/material";

function EditPageSection({children, className, title}) {
    return <div className="EditPageSection">
        <Typography variant="subtitle2">{title}</Typography>
        <div className={className} >{children}</div>
    </div>
}

export default EditPageSection;
