import { Button } from '@mui/material';
import { Add, LocationSearching } from '@mui/icons-material';
import DisplayWithPrivilege from '../DisplayWithPrivilege';
import { IconButton } from "@mui/material";
import './MapButtons.css';

function RoundMapButton({className, onClick, children}) {
    return <div className={className ? "RoundMapButton " + className : "RoundMapButton"}>
        <IconButton onClick={onClick} size='medium'>
            {children}
        </IconButton>
    </div>
}

function MapButtons({newLocationPos, onAddButton, onConfirmButton, onCancelButton, onMyLocationButton}) {
    function renderCreationButtons() {
        return <div className="CreationButtons">
            <div className='LatLngDisplay'>Lat: {newLocationPos[0].toFixed(6)} Lng: {newLocationPos[1].toFixed(6)}</div>
            <DisplayWithPrivilege privilege="canCreateLocations">
                <Button variant='contained' onClick={onConfirmButton}>Create Location</Button>
            </DisplayWithPrivilege>
            <Button variant='outlined' onClick={onCancelButton}>Cancel</Button>
        </div>
    }
    
    function renderNonCreationButtons() {
        return <div className="NonCreationButtons">
            <RoundMapButton onClick={onMyLocationButton}>
                <LocationSearching fontSize='large' />
            </RoundMapButton>
            <RoundMapButton onClick={onAddButton}>
                <Add fontSize='large' />
            </RoundMapButton>
        </div>
    }

    return <div className='MapButtons'>
        {newLocationPos ? renderCreationButtons() : renderNonCreationButtons()}
    </div>
}

export default MapButtons;