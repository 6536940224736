import { Create } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import NewMessageGroupModal from '../NewMessageGroupModal';
import MessageGroupCard from '../MessageGroupCard';
import { useState } from "react";
import './MessageGroupList.css';

function MessageGroupList({messageGroups, selectedMessageGroupId, onMessageGroupSelect, onNewMessageGroup}) {
    const [newMessageGroupModalOpen, setNewMessageGroupModalOpen] = useState(false);

    return <div className="MessageGroupList">
        <div className="MessageGroupListHeader">
            <h5 className="MessageGroupListTitle">Messages</h5>
            <div className="NewMessageGroupButton">
                <Tooltip title="New Message" >
                    <IconButton onClick={() => setNewMessageGroupModalOpen(true)}><Create /></IconButton>
                </Tooltip>
            </div>
        </div>
        <div className="MessageGroupListBody">
            {messageGroups?.map(messageGroup => <MessageGroupCard
                key={messageGroup.id}
                onClick={() => onMessageGroupSelect(messageGroup)}
                messageGroup={messageGroup} 
                isSelected={selectedMessageGroupId === messageGroup.id}/>)}
        </div>
        <NewMessageGroupModal open={newMessageGroupModalOpen} onSave={onNewMessageGroup} onClose={() => setNewMessageGroupModalOpen(false)} />
    </div>
}

export default MessageGroupList;
