import { useEffect, useState } from 'react';
import { getLocationById, getSharedUsersForLocation, shareLocationWithUser, unshareLocationWithUser } from '../../backendclient';
import SharingModal from '../SharingModal';

function LocationSharingModal({open, onClose, locationId}) {
    const [location, setLocation] = useState(null);

    useEffect(() => {
        if (!locationId) return;
        getLocationById(locationId)
            .then(setLocation)
            .catch(console.log);
    }, [locationId]);

    async function share(userId) {
        return await shareLocationWithUser(locationId, userId);
    }

    async function unshare(userId) {
        return await unshareLocationWithUser(locationId, userId);
    }

    async function getSharedUsers() {
        return await getSharedUsersForLocation(locationId);
    }

    return <SharingModal
        open={open}
        onClose={onClose}
        resourceDisplayName={location ? location.name : ""}
        shareWithUser={share} 
        unshareWithUser={unshare}
        getUsersWithAccess={getSharedUsers} />
}

export default LocationSharingModal;
