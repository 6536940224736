import Typography from '@mui/material/Typography';
import { Button, Dialog, IconButton, Input } from '@mui/material';
import './SharingModal.css';
import { useEffect, useState } from 'react';
import { getUsers } from '../../backendclient';
import { Add, Cancel, Person, Search } from '@mui/icons-material';

function SharingModalContent({onClose, resourceDisplayName, getUsersWithAccess, shareWithUser, unshareWithUser}) {
    const [usersWithAccess, setUsersWithAccess] = useState(null);
    const [users, setUsers] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [reloadToken, setReloadToken] = useState(0);

    useEffect(() => {
        getUsersWithAccess()
            .then(setUsersWithAccess)
            .catch(console.log);
    }, [getUsersWithAccess, reloadToken])

    useEffect(() => {
        getUsers()
            .then(setUsers)
            .catch(console.log);
    }, [reloadToken])

    function userInSharedList(user) {
        return usersWithAccess.filter(u => u.id === user.id).length > 0;
    }

    function getAllUserCards() {
        const usersAfterSearch = users.filter(u => u.username.includes(searchString));

        if (usersAfterSearch.length === 0) {
            return <Typography variant='caption'>No users match search...</Typography>
        }

        return usersAfterSearch.map(u => userInSharedList(u) ? null : <SharingUserCard user={u} isShared={false} />)
    }

    async function share(user) {
        await shareWithUser(user.id);
        setReloadToken(t => t+1);
    }

    async function unshare(user) {
        await unshareWithUser(user.id);
        setReloadToken(t => t+1);
    }

    function SharingUserCard({user, isShared, key}) {
        return <div key={key} className='SharingUserCard'>
            <Person fontSize='small' />
            <Typography style={{margin: "auto", flexGrow: 10}} variant='body'>{user.username}</Typography>
            {isShared ? <IconButton size='small' onClick={() => unshare(user)}><Cancel fontSize='small' /></IconButton> : <IconButton size='small' onClick={() => share(user)}><Add fontSize='small' /></IconButton>}
        </div>
    }

    if (!usersWithAccess || !users) {
        return "Loading...";
    }

    return <div className='SharingModal'>
        <Typography variant='h5'>Share {resourceDisplayName}</Typography>
        
        <div className='SharingSectionTitle'><Typography variant='h7'>All Users</Typography></div>
        <div className='UserSearch'>
            <Search size="Large" />
            <Input className='UserSearchInput' placeholder="Search" size='small' value={searchString} onChange={(e) => setSearchString(e.target.value)}/>
        </div>
        <div className='UserCards'>
            {getAllUserCards()}
        </div>

        <div className='SharingSectionTitle'><Typography variant='h7'>Users With Access</Typography></div>
        <div className='UserCards'>
            {usersWithAccess.length > 0 ? usersWithAccess.map(u => <SharingUserCard key={u.id} user={u} isShared={true} />) : <Typography variant='caption'>{resourceDisplayName} isn't shared with any users...</Typography>}
        </div>

        <Button onClick={onClose}>Close</Button>
    </div>
}

function SharingModal({open, onClose, resourceDisplayName, getUsersWithAccess, shareWithUser, unshareWithUser}) {
    return <Dialog fullWidth open={open} onClose={onClose}>
        <SharingModalContent
            onClose={onClose}
            resourceDisplayName={resourceDisplayName}
            getUsersWithAccess={getUsersWithAccess}
            shareWithUser={shareWithUser}
            unshareWithUser={unshareWithUser} />
    </Dialog>
}

export default SharingModal;
