import CurrentUserContext from "../../contexts/CurrentUserContext";
import { Badge, IconButton, Tooltip } from '@mui/material';
import { Favorite, FavoriteBorder } from '@mui/icons-material';

function LikeButton({currentUser, size, likedByUsers, likeFunc, unlikeFunc, tooltipResourceName, onChange}) {
    const likedByUser = likedByUsers.filter(({id}) => currentUser && id === currentUser.id).length > 0;

    async function handleClick() {
        await likedByUser ? await unlikeFunc() : await likeFunc();
        onChange();
    }

    return <Tooltip title={likedByUser ? `Unlike ${tooltipResourceName || ""}` : `Like ${tooltipResourceName || ""}`}>
        <IconButton size={size} onClick={handleClick} className='LikeDisplay'>
            <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} badgeContent={likedByUsers.length > 0 ? likedByUsers.length : null}>
                {likedByUser ? <Favorite fontSize={size} /> : <FavoriteBorder fontSize={size} />}
            </Badge>
        </IconButton>
    </Tooltip>
}

const LikeButtonWrapper = (props) => <CurrentUserContext.Consumer>
    {currentUser => <LikeButton currentUser={currentUser} {...props} />}
</CurrentUserContext.Consumer>;;

export default LikeButtonWrapper;
