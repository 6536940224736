import {  useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Checkbox, Dialog } from '@mui/material';
import './LocationCollectionModal.css';
import {addLocationToCollection, getCollections, removeLocationFromCollection} from '../../backendclient';
import { Collections as CollectionIcon } from '@mui/icons-material';
import AddCollectionButton from '../AddCollectionButton';

function CollectionCard({isChecked, collection, onChange}) {
    return <div className='CollectionCard'>
        <CollectionIcon fontSize='large' />
        <Typography style={{flexGrow: 10, margin: "auto", marginLeft: "10px"}}>{collection.name}</Typography>
        <Checkbox checked={isChecked} onChange={onChange} />
    </div>
}

function LocationCollectionModal({open, locationId, onClose}) {
    const [collections, setCollections] = useState(null);
    const [reloadToken, setReloadToken] = useState(0);

    useEffect(() => {
        if (!open) {
            return;
        }

        getCollections()
            .then(setCollections)
            .catch(console.log);
    }, [locationId, open, reloadToken]);

    async function handleCollectionAdd(collectionId, locationId) {
        await addLocationToCollection(collectionId, locationId)
            .then(() => setReloadToken(v => v+1))
            .catch(console.log);
    }

    async function handleCollectionRemove(collectionId, locationId) {
        await removeLocationFromCollection(collectionId, locationId)
            .then(() => setReloadToken(v => v+1))
            .catch(console.log);
    }

    function getCollectionCard(collection) {
        const isChecked = collection.locations.filter(l => l.id === locationId).length > 0;

        function onChange(e) {
            if (isChecked) {
                handleCollectionRemove(collection.id, locationId);
            } else {
                handleCollectionAdd(collection.id, locationId);
            }
        }

        return <CollectionCard key={collection.id} collection={collection} isChecked={isChecked} onChange={onChange} />
    }

    return <Dialog fullWidth open={open} onClose={onClose}>
        <div className='LocationCollectionModal'>
            <Typography variant="h6">Add or Remove Location From Collections</Typography>

            {collections ? <div className='CollectionCards'>
                {collections.length > 0 ? collections.map(getCollectionCard) : <Typography>Looks like you don't have any collections yet...</Typography>}
            </div> : "Loading..."}

            <div style={{display: "flex", marginTop: 10}}>
                <AddCollectionButton onSave={() => setReloadToken(v => v+1)}/>
                <Button variant="contained" onClick={onClose} style={{marginLeft: "auto"}}>Close</Button>
            </div>
        </div>
    </Dialog>
}

export default LocationCollectionModal;
