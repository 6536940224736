// TODO there should be a test that verifies these all can be rendered to a string
const EVENT_TYPES = {
    locationAdded: "locationAdded",
    locationModified: "locationModified",
    locationDeleted: "locationDeleted",
    locationImageAdded: "locationImageAdded",
    userInvited: "userInvited",
    commentAdded: "commentAdded",
    announcement: "announcement",
    locationShared: "locationShared",
    collectionShared: "collectionShared",
    locationLiked: "locationLiked",
    imageLiked: "imageLiked",
    commentLiked: "commentLiked",
};

const WEB_SOCKET_EVENT_TYPES = {
    newMessage: "newMessage",
    newMessageGroup: "newMessageGroup",
    updateMessageGroup: "updateMessageGroup",
    messageRead: "messageRead",
    error: "error",
    heartbeat: "heartbeat",
    initialLoadComplete: "initialLoadComplete",
}

const MINIMUM_PASSWORD_LENGTH = 6;
const MINIMUM_USERNAME_LENGTH = 4;

module.exports = {
    EVENT_TYPES,
    WEB_SOCKET_EVENT_TYPES,
    MINIMUM_PASSWORD_LENGTH,
    MINIMUM_USERNAME_LENGTH
};
