import CurrentUserContext from '../../contexts/CurrentUserContext';

function DisplayWithPrivilege({privilege, privileges, children, alternate}) {
    privileges = privileges || [];
    if (privilege) privileges.push(privilege);

    return <CurrentUserContext.Consumer>
        {currentUserValue => {
            if (!currentUserValue) {
                return null;
            }

            if (currentUserValue.privileges && currentUserValue.privileges.isSuperUser) {
                return children;
            }

            for (const p of privileges) {
                if (currentUserValue.privileges[p]) {
                    return children;
                }
            }

            return alternate;
        }}
    </CurrentUserContext.Consumer>;
}

export default DisplayWithPrivilege;
