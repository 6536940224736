import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router';
import './LoginPage.css';
import { useSearchParams } from 'react-router-dom';
import { login } from '../../backendclient';

function LoginPage({onLogin}) {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();
    const redirect = searchParams.get("redirect");
    const [loginInfo, setLoginInfo] = useState({
        username: "",
        password: ""
    });

    function getErrorMessage(err) {
        if (err.response && err.response.status === 404) {
            return "That user doesn't exist. Are you sure you entered your username correctly?"
        }
        return error.message;
    }

    function handleChange(e) {
        setLoginInfo(loginInfo => ({
            ...loginInfo,
            [e.target.id]: e.target.value.trim() // we trim this because sometimes phones add trailing spaces
        }));
    }

    async function loginHandler() {
        login(loginInfo)
            .then(() => {
                onLogin();
                navigate(redirect || "/map");
            })
            .catch(setError);
    }
    
    return (
        <div className='Container LoginPage'>
            <form onSubmit={(e) => {
                e.preventDefault();
            }}>
                <div>
                    <TextField label="Username" id="username" onChange={handleChange} />
                </div>
                <div>
                    <TextField label="Password" id="password" type="password" onChange={handleChange} />
                </div>
                <div>
                    <Button type='submit' onClick={loginHandler} variant='contained'>Login</Button>
                </div>
                <div>
                    {error ? <Typography color="error">{getErrorMessage(error)}</Typography> : null}
                </div>
                <div className='ContactEmail'>
                    <Typography variant="caption">Trouble logging in? Contact us at <a href="mailto:admin@goldhillstradingpost.net">admin@goldhillstradingpost.net</a></Typography>
                </div>
            </form>
        </div>
    );
}

export default LoginPage;
